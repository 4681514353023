.pdf-controls {
  position: relative;
  
}

@media (max-width: 720px) {
  .pdf-controls {
    display: none;
    /* transform: translate(0px, -140px); */

  }
}

.pdf-controls-container {
  position: absolute;
  bottom: 0;
  right: 0;
  display: flex;
  flex-direction: column;
  padding-right: 2%;
  padding-bottom: 2%;
}

.pdf-controls button {
  width: 1.75em;
  height: 1.75em;
  font-size: 18px;
  border-radius: 100%;
  margin: 0.25em 0;
  border: 1px solid lightgrey;
}
.pdf-controls button:focus {
  outline: none;
  box-shadow: 0 0 3px 2px #b2f2ff;
}
.pdf-controls .zoom-to-fit {
  margin-bottom: 1em;
}
