@import "../../../node_modules/bootstrap/scss/bootstrap";

$gwBlue: #16a5a3;
$gwPink: #da2864;
$gwGrey: "";
$gwLightGrey: #eeecea;

:root {
  --primary-color: $gwBlue;
  --secondary-color: $gwPink;
  --background-color: black;
  --text-color: white;
}

$grid-breakpoints: (
  xs: 0,
  sm: 576px,
  md: 768px,
  lg: 1200px,
  xl: 1400px,
  xxl: 1400px,
);

@font-face {
  font-family: "Caligosta";
  src: url(../fonts/Calistoga-Regular.ttf) format("truetype");
}

@mixin screen-min($min) {
  @media (min-width: $min) {
    @content;
  }
}

@mixin screen-max($max) {
  @media (max-width: $max) {
    @content;
  }
}

html,
body {
  background-color: var(--background-color);
}

body {
  font: 400 "Poppins" !important;
}

.price {
  h3 {
    font-family: Caligosta;
  }
}

@include screen-max(1200px) {
  body {
    font-size: 12px !important;
  }

  h5,
  h3 {
    font-size: 14px !important;
  }

  span,
  p {
    font-size: 12px !important;
  }

  .card-body {
    padding: 5px !important;
  }

  .card-text {
    text-align: left;
    line-height: normal;
    line-break: normal;
  }
}

.Body {
  @include screen-max(1200px) {
    width: 100%;
  }
}

.navbar {
  position: absolute !important;
  width: 100vw;
  top: 0;
  display: flex;
  align-items: center;
  // box-shadow: 0px 0px 10px -7px black;
  z-index: 1000;

  background-color: transparent !important;

  .logoContainer {
    width: 115px;
    height: 100%;
    margin: auto;

    @include screen-max(820px) {
      width: 60px;
    }

    img {
      width: 100%;
      height: 100%;
    }
  }
}

.product-precision-table-line {
  border-top: hidden;
  margin-top: -2px;
}

.product-resume-precisions-choices-line {
  padding-top: unset !important;
  padding-bottom: unset !important;
}

.product-resume-precisions-choices {
  margin-left: 5%;
}

.home-sheet .MenuContainer {
  @include screen-max(1200px) {
    display: none;
  }
}

//// Home/index.jsx
.MenuContainer {
  // box-shadow: -21px 0 33px 0px black;
  z-index: 1100;
  display: flex;
  justify-content: center;
  height: 100vh;

  @include screen-max(1200px) {
    width: 100vw;
    height: 59px;
    position: fixed;
    bottom: 0px;
    background-color: var(--background-color);
  }
}

.MenuLeft {
  @include screen-max(1200px) {
    display: none;
  }

  box-shadow: -21px 0 33px 0px var(--text-color);
  background-color: var(--background-color);
  position: -webkit-fixed;
  position: -moz-fixed;
  position: -ms-fixed;
  position: -o-fixed;
  position: fixed;
  // top: 0 !important;
  // top: 0vh;
  z-index: 25 !important;
  // height: auto;
  height: 100vh;
  width: 16.66666667%;
  padding: 15px;

  .nav {
    border: none;

    .nav-item {
      margin: 15px;
    }

    a {
      display: block;
      color: var(--text-color);
      text-decoration: none;
      text-align: left;
      padding: 0px 0px 15px 0px;

      svg {
        margin-right: 10px;
        fill: var(--text-color);

        .custom_color-path-stroke {
          stroke: var(--text-color);
        }

        .custom_color-stroke {
          stroke: var(--secondary-color);
        }

        .custom_color-fill {
          fill: var(--secondary-color);
        }
      }
    }

    a.active {
      color: var(--text-color);
      text-decoration: none;
      background-color: var(--background-color);
      border-bottom: 4px solid var(--primary-color);
    }
  }
}

.MenuBottom {
  width: 100%;
  height: 105%;
  display: flex;
  // box-shadow: 0 0 10px -4px black;
  box-shadow: var(--text-color) 0px 0px 10px -2px;
  background-color: var(--background-color);

  @include screen-min(1200px) {
    display: none;
  }

  .nav {
    flex-wrap: nowrap;
    // align-items: end;
    width: 100%;
    justify-content: space-around !important;

    @include screen-min(820px) {
      justify-content: center !important;
    }

    .nav-item {
      // white-space: nowrap;
      text-align: center;
      width: 90px;

      @include screen-max(1200px) {
        margin: 0px 30px;
      }

      @include screen-max(720px) {
        margin: unset;
      }

      a {
        color: var(--text-color);
        text-decoration: none;
        display: flex;
        flex-direction: column;
        align-items: center;
        font-size: 8px;
        height: 59px;
        margin: auto;
        justify-content: center;
        padding: 5px 15px 0px;

        svg {
          margin-bottom: 5px;
          transform: translate(0px, 4px);
          fill: var(--text-color);

          .custom_color-path-stroke {
            stroke: var(--text-color);
          }

          .custom_color-stroke {
            stroke: var(--secondary-color);
          }

          .custom_color-fill {
            fill: var(--secondary-color);
          }
        }

        #icon-activities {
          transform: translate(4px, 4px);
        }
      }

      a.active {
        color: var(--text-color);
        text-decoration: none;
        background-color: var(--secondary-color);
        // border-top: 4px solid $gwBlue;
        // box-shadow: 0 -6px 1px -2px var(--primary-color);
        border-radius: 5px 5px 0 0;
        color: white;
        padding: 5px 15px 0px;
        box-shadow: 0px 2px 15px -3px var(--text-color);

        svg {
          fill: white;

          .custom_color-fill {
            fill: white;
          }

          .custom_color-stroke {
            stroke: white;
          }

          .custom_color-path-stroke {
            stroke: white;
          }

          transform: translate(0px, 4px);
        }

        #icon-activities {
          transform: translate(4px, 4px);
        }

        // .Icon_Info {

        //     .custom_color-stroke {
        //         stroke: white;
        //     }
        // }
      }
    }
  }
}

// DEBUT// barre de titre de l'hotel
.hostName {
  text-align: center;
  background-color: var(--background-color);
  z-index: 25 !important;
  height: auto;

  h5 {
    font-size: 20px;
    color: var(--text-color);
  }

  figure {
    width: 100%;
    height: 250px;
    overflow: hidden;

    img {
      max-height: 60%;
      max-width: 100%;
      display: block;
      position: relative;
      left: 50%;
      top: 50%;
      // margin: auto;
      transform: translate(-50%, -50%);
    }
  }

  padding-bottom: 25px;

  @include screen-max(820px) {
    padding-bottom: 5px;
  }

  .hostInfosCollapse {
    display: flex;

    // margin: auto;
    img {
      width: 45px;
      height: auto;
    }

    a {
      text-decoration: none;
      color: var(--primary-color);
    }

    i {
      margin-right: 5px;
    }
  }
}

.accordion-button {
  background-color: transparent !important;
  color: var(--text-color) !important;
  box-shadow: none;
  padding: 0.5rem 1rem 0.5rem 1rem !important;

  // padding: 0;
  h5 {
    width: 100%;
    text-align: center;
    margin: 0px !important;
  }
}

.accordion-button:focus {
  border: none !important;
  box-shadow: none !important;
}

.accordion-button:not(.collapsed) {
  box-shadow: none;
}

.accordion-button::after {
  background-image: url(../images/info-circle.svg) !important;
  background-size: 0.95rem !important;
}

// FIN // barre de titre de l'hotel

//// informations/index.jsx
.informations {
  // margin-top: 40px;
  padding: 0px !important;
  display: inline;

  .mainInfos {
    span {
      i {
        margin-right: 10px;
      }
    }
  }

  .mainImg {
    width: 100%;

    @include screen-max(820px) {
      height: 170px;
    }

    height: 90vh;

    figure {
      background-position: center;
      background-repeat: no-repeat;
      background-size: cover;
      width: auto;
      height: 100%;
    }

    // .legend {
    //     @include screen-max(820px) {
    //         display: none;
    //     }
    //     position: relative;
    //     z-index: 32;
    //     bottom: 336px;
    //     width: 640px;
    //     text-align: left;
    //     white-space: pre-line;
    //     font-size: 32px;
    //     margin-left: 55px;
    //     color: black;
    //     text-shadow: 1px 1px 1px white;
    // }
  }

  .socialMedias {
    width: 100%;
    display: block;
    text-align: right;
    position: relative;
    top: -40px;

    @include screen-max(820px) {
      top: -20px;
    }

    i {
      color: white;
      text-shadow: black 2px 2px 0px;
    }
  }
}

//// home/index.jsx

.home {
  display: flex;

  @include screen-max(1200px) {
    display: block;
  }

  .nav-link {
    color: var(--text-color);
  }
}

.itemComponent {
  position: relative;
  height: max-content;
  overflow-y: scroll;
  background-color: var(--background-color);

  .topName {
    text-align: center;
    z-index: 1000;
    position: fixed;
    padding: 15px;
    font-size: 15px;
    display: none;
    background-color: var(--background-color);
    width: 100%;
    color: var(--text-color);

    @include screen-max(1200px) {
      display: block;
      font-size: 20px;
    }

    @include screen-max(720px) {
      font-size: 15px;
    }
  }
}

//// Menu/index.jsx
.menuContainer {
  width: 85%;
  overflow-x: scroll;
  overflow-y: hidden;
  position: fixed !important;
  top: 0px;
  padding-top: 15px;
  background-color: var(--background-color) !important;

  // @include screen-max(820px) {
  //     top: 66px;
  // }
  // z-index: 777;
  // @include screen-max(820px) {
  // }
  @include screen-max(1200px) {
    width: 100%;
    justify-content: start !important;
  }

  .menuCategory {
    width: 100%;
    display: flex;
    padding: 0px;
    margin: auto;
    margin-bottom: 0px !important;
    width: max-content;
    flex-wrap: nowrap;
    background-color: var(--background-color);

    @include screen-max(1200px) {
      width: max-content;
    }

    a {
      padding: 10px !important;
      margin: 5px;
      border: 0.2px solid grey;
      border-radius: 5px;
      box-shadow: 0 0 1em -13px var(--text-color);
      white-space: nowrap;
      background-color: var(--background-color);

      @include screen-max(1200px) {
        white-space: nowrap;
      }
    }

    a.activeCategoryLink {
      background-color: var(--primary-color) !important;
      color: white;
    }

    a.defaultActive {
      background-color: var(--primary-color) !important;
      color: white;
    }

    a.active {
      background-color: var(--primary-color) !important;
    }
  }
}

//// items/index.jsx

.mainItems {
  padding: 0px !important;
  // margin-top: 15px;
  // background-color: #eeecea6e;
  padding-top: 90px !important;

  @include screen-max(1200px) {
    padding: 115px 0px !important;
  }

  // @include screen-max(820px) {
  //     padding-top: 110px 0px !important;
  // }

  // background-color: green;

  .itemsGroup {
    @include screen-max(1200px) {
      gap: 15px;
    }

    // .ancor {
    //     height: 45px;
    //     width: 25px;
    //     position: absolute;
    //     // z-index: -1;
    //     transform: translate(10px, -120px);
    //     @include screen-max(820px) {
    //         transform: translate(10px, -140px);
    //     }
    // }

    h4 {
      text-align: left;
      padding-bottom: 15px;
      color: var(--primary-color);
      border-bottom: 1px solid #16a5a345;
      font-size: 20px;

      @include screen-max(1200px) {
        margin-left: 0px;
        font-size: 16px;
        padding-bottom: 5px;
        margin-bottom: 20px;
      }
    }

    padding-bottom: 25px;

    .card-columns {
      padding: 0 40px 0 40px;
      justify-content: start;
      margin: auto;

      @include screen-max(1200px) {
        padding: 0px;
        margin: 0px;
        column-gap: 2%;
        justify-content: flex-start;
      }

      @include screen-max(720px) {
        padding: 0px;
        margin: 0px;
        column-gap: 2%;
        justify-content: space-between;
      }
    }

    .item {
      background-color: white !important;
      margin-top: 15px;
      height: 467px;
      padding: 0px;
      margin: 15px;
      width: 274px;
      box-shadow: 0 0 39px -31px black;
      border-radius: 5px !important;

      @include screen-max(1200px) {
        height: 350px;
        max-width: 27%;
        // margin: 0px 0px 40px 0px;
      }

      @include screen-max(720px) {
        height: 267px;
        // max-width: 163px !important;
        max-width: 48%;
        margin: 0px;
        column-gap: 10px;
        margin-bottom: 20px;
      }

      margin-bottom: 30px;
      border: none;

      a {
        color: black;
        text-decoration: none;

        .cardImg-top {
          height: 287px;
          width: 100%;
          border-radius: 5px 5px 0px 0px;

          @include screen-max(1200px) {
            height: 220px;
          }

          @include screen-max(720px) {
            width: 100%;
            height: 153px;
            margin: 0px;
          }
        }

        .detailPrice {
          background-color: transparent;
          width: 100%;
          height: 1px;
          display: -webkit-box;
          justify-content: end;
          -webkit-box-pack: end;
          /* justify-content on Safari*/
          -webkit-box-align: end;

          .priceWrapper {
            .price {
              background-color: white;
              width: clamp(100%, 100%, 45px);
              padding: 5px 5px 5px 15px;
              border-radius: 10px 0 0 0;
              transform: translate(0px, -4px);
              height: max-content;
              display: flex;
              flex-direction: row;
              flex-wrap: nowrap;
              align-content: flex-end;
              justify-content: center;
              align-items: flex-end;

              @include screen-max(720px) {
                transform: translate(0px, 10px);
                padding: 2px 5px 2px 10px;
              }

              h3 {
                font-size: 20px !important;

                @include screen-max(1200px) {
                  // transform: translate(0px, 10px);
                  padding: 2px 5px 2px 10px;
                }

                h3 {
                  font-size: 20px !important;

                  @include screen-max(1200px) {
                    font-size: 18px !important;
                  }
                }
              }

              .asterisk {
                display: flex;
                color: var(--primary-color);
                font-size: 30px;
                transform: translate(0px, -10px);
              }
            }
          }
        }

        .card-header {
          border: none;
          background-color: white;
          padding: 0rem 0.5rem 0.5rem 0.5rem;

          .asterisk-details {
            color: var(--primary-color);

            p {
              font-size: 12px !important;
              white-space: nowrap;
              overflow: hidden;
              text-overflow: ellipsis;
              margin: 6px 0 -4px 0;
              width: 260px;

              svg {
                width: 16px;
                height: 16px;
                margin-right: 5px;
              }

              @include screen-max(1200px) {
                width: 150px;
                font-size: 11px !important;

                svg {
                  width: 12px;
                  height: 12px;
                  margin-right: 2px;
                }
              }
            }
          }
        }

        .card-title {
          font-size: 18px;
          text-align: left;
          background: white;
          padding-top: 9px;
          white-space: nowrap;
          width: 258px;
          overflow: hidden;
          text-overflow: ellipsis;
          margin: 0;

          @include screen-max(1200px) {
            width: 150px;
          }
        }

        .itemBody {
          padding: 0rem !important;
          background-color: white;
          color: #909090;
          margin-bottom: 15px;

          .description {
            display: -webkit-box;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;
            overflow: hidden;
            text-overflow: ellipsis;
            margin: 0 !important;
            text-align: left;

            @include screen-max(1200px) {
              font-size: 12px !important;
            }
          }
        }

        .card-footer {
          border: none;
          background-color: white;
          padding-bottom: 17px;
          border-radius: 0 0 5px 5px;
          position: relative;
          z-index: 24;

          @include screen-max(1200px) {
            padding: 0px;
            padding-bottom: 10px;
          }

          .AddBtn-container {
            z-index: 999;

            h3 {
              margin: 0;
              font-size: 25px;
            }

            justify-content: end;
            width: auto;
            align-items: center;

            .col {
              text-align: center;
            }

            .AddBtn {
              @include screen-max(1200px) {
                padding: 2px 6px;
                font-size: 12px;
              }

              border: none;
              color: white;
              background-color: var(--primary-color);

              width: fit-content;

              i {
                font-size: 15px;
              }
            }
          }
        }
      }
    }

    .item:hover {
      box-shadow: 0 0 10px -5px black;
      border-radius: 15px;
    }
  }
}

//// offers/index.jsx
.mainScreenAlentour {
  position: absolute;
  top: 0px;
  left: 0;
  width: 100%;
  border: 0;
}

.mainOfferAlentour {
  padding-top: 65px !important;
  padding-bottom: 60px !important;
  height: 100vh;
}

.mainOffers {
  padding: 0px !important;
  padding-top: 90px !important;
  height: 100vh;

  .footer-wrapper.alentour,
  #footer {
    display: none !important;
  }

  .headerDescription {
    font-size: 20px;
    text-align: center;
    width: 54ch;
    margin: auto;
    color: var(--text-color);

    @include screen-max(720px) {
      padding: 15px 10px 0px 10px;
      text-align: center;
      width: 100%;
    }
  }

  .offersGroup {
    @include screen-max(720px) {
      gap: 15px;
    }

    .ancor {
      height: 45px;
      width: 25px;
      position: absolute;
      // z-index: -1;
      transform: translate(10px, -120px);

      @include screen-max(720px) {
        transform: translate(10px, -140px);
      }
    }

    h4 {
      text-align: left;
      padding-bottom: 15px;
      color: var(--primary-color);
      border-bottom: 1px solid #16a5a345;
      font-size: 20px;

      @include screen-max(720px) {
        margin-left: 0px;
        font-size: 16px;
        padding-bottom: 5px;
        margin-bottom: 20px;
      }
    }

    padding-bottom: 25px;

    .card-columns {
      padding: 0 40px 0 40px;
      justify-content: start;
      margin: auto;

      @include screen-max(720px) {
        padding: 0px 10px;
        margin: 0px;
        column-gap: 20px;
      }
    }

    .offer {
      display: block;
      background-color: white !important;
      margin-top: 15px;
      height: 180px !important;
      padding: 0px;
      margin: 15px;
      width: 100%;
      flex-direction: row;
      box-shadow: 0 0 7px -5px black;

      @include screen-max(720px) {
        height: 140px !important;
        width: 100%;
        margin: 0px;
        column-gap: 10px;
        margin-bottom: 20px;
      }

      a {
        color: black;
        text-decoration: none;
      }

      .img {
        @include screen-max(720px) {
          padding-right: 0px;
        }

        .pills {
          transform: translate(-9px, 18px);
          width: max-content;
          position: relative;
          z-index: 999;
          background-color: var(--primary-color);

          @include screen-max(1200px) {
            transform: translate(-9px, 10px);
            width: max-content;
            position: relative;
            z-index: 999;
            background-color: var(--primary-color);
          }

          svg {
            transform: translate(0px, -17px);

            @include screen-max(1200px) {
              transform: translate(0px, -12.5px);
            }
          }

          span {
            text-align: center;
            transform: translate(-8px, 0px);
            display: inline-block;
            color: white;
            text-transform: uppercase;
          }
        }
      }

      .description {
        @include screen-max(720px) {
          padding-left: 0px;
        }

        svg {
          margin-right: 5px;
        }

        .distance {
          font-size: 15px;

          @include screen-max(720px) {
            font-size: 10px;
          }
        }
      }

      .Offer {
        font-weight: 700;
        color: var(--secondary-color);
        transform: translate(500px, -73px);
        width: 523px;
        text-align: center;

        @include screen-max(1200px) {
          transform: translate(0px, -73px);

          // width: 50%;
          width: 370px;
          margin-left: 255px;
          text-align: center;
        }

        @include screen-max(720px) {
          margin-left: 0px;
          transform: translate(0px, -15px);
          width: 351px;
        }
      }

      margin-bottom: 30px;
      border: none;

      .cardImg-top {
        transform: translate(0px, -24px);

        overflow: hidden;
        height: 180px;
        width: 100%;
        border-radius: 5px 0px 0px 5px;

        @include screen-max(1200px) {
          transform: translate(0px, -18px);
        }

        @include screen-max(720px) {
          width: 100%;
          height: 115px;
          margin: 0px;
          transform: translate(0px, -18px);
          border-radius: 5px 0px 0px 0px;
        }
      }

      .box-shadow {
        height: 287px;
      }

      .box-shadow::after {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        box-shadow: inset 0 0 10px 10px #ffffff59, 0 0 39px -31px black;
        overflow: hidden;
        border-radius: 5px;
      }

      .detailPrice {
        background-color: transparent;
        width: 100%;
        height: 1px;
        display: -webkit-box;
        justify-content: end;
        -webkit-box-pack: end;
        /* justify-content on Safari*/
        -webkit-box-align: end;

        .price {
          background-color: white;
          width: max-content;
          padding: 0px 5px 5px 15px;
          border-radius: 10px 0 0 0;
          transform: translate(0px, 14px);
          height: max-content;
          display: flex;
          flex-direction: row;
          flex-wrap: nowrap;
          align-content: center;
          justify-content: center;
          align-items: center;

          @include screen-max(720px) {
            transform: translate(0px, 10px);
            padding: 2px 5px 2px 10px;
            font-size: 18px !important;
          }

          h3 {
            font-size: 20px !important;

            @include screen-max(720px) {
              font-size: 18px !important;
            }
          }

          .asterisk {
            color: var(--primary-color);
            font-size: 30px;
            transform: translate(0px, -10px);
          }
        }
      }

      .card-header {
        border: none;
        background-color: white;
        padding: 1rem 0.5rem 0rem 0.5rem;

        @include screen-max(720px) {
          padding: 0rem 0.5rem 0rem 0.5rem;
        }
      }

      .CardCategory {
        font-size: 15px;
        margin-top: 5px;
      }

      .card-title {
        font-size: 18px;
        text-align: left;
        background: white;
        // padding-top: 9px;
        white-space: nowrap;
        width: 258px;
        overflow: hidden;
        text-overflow: ellipsis;
        margin: 0;
        margin-top: 10px;
        margin-bottom: 10px;

        @include screen-max(720px) {
          width: 150px;
          margin-top: 5px;
          margin-bottom: 5px;
        }
      }

      .offerBody {
        padding: 0rem !important;
        background-color: white;
        color: #909090;
        margin-bottom: 15px;

        .description {
          display: -webkit-box;
          // -webkit-line-clamp: 2;
          // -webkit-box-orient: vertical;
          // overflow: hidden;
          // text-overflow: ellipsis;

          margin: 0 !important;
          text-align: left;

          @include screen-max(720px) {
            display: none;
          }

          svg {
            width: 15px;
            height: 15px;
          }
        }

        .description_mobile {
          margin: 0 !important;
          text-align: left;
        }
      }

      .card-footer {
        border: none;
        background-color: transparent;
        padding-bottom: 17px;
        border-radius: 0 0 5px 5px;
        position: relative;
        z-index: 24;

        @include screen-max(720px) {
          padding: 0px;
          padding-bottom: 10px;
          // background-color: white;
        }

        .AddBtn-container {
          z-index: 999;

          @include screen-max(1200px) {
            display: none;
          }

          h3 {
            margin: 0;
            font-size: 25px;
          }

          justify-content: end;
          width: auto;
          align-items: center;

          .col {
            text-align: right;

            @include screen-max(720px) {
              text-align: center;
            }
          }

          .AddBtn {
            @include screen-max(720px) {
              padding: 2px 6px;
              font-size: 12px;
            }

            border: none;
            color: white;
            background-color: var(--primary-color);
            transform: translate(5px, -20px);

            width: fit-content;

            i {
              font-size: 15px;
            }
          }
        }
      }
    }

    .offer:hover {
      box-shadow: 0 0 10px -5px black;
      border-radius: 15px;
    }
  }

  ///////////////////////////FIIIIIIIIIIIIIIIIIIIIIIIIIIIIIIIIIIIIIIIIIIIIIIIIIIIIIIIIIIIIIIIN
}

//// activities/activities.jsx

.mainActivities {
  padding: 0px !important;
  padding-top: 90px !important;

  @include screen-max(1200px) {
    padding-top: 130px !important;
  }

  @include screen-max(720px) {
    padding-top: 115px !important;
  }

  .headerDescription {
    font-size: 20px;
    text-align: center;
    width: 54ch;
    margin: auto;
    color: var(--text-color);

    @include screen-max(720px) {
      padding: 15px 10px 0px 10px;
      text-align: center;
      width: 100% !important;
    }
  }

  .activitiesGroup {
    @include screen-max(720px) {
      gap: 15px;
    }

    .ancor {
      height: 45px;
      width: 25px;
      position: absolute;
      // z-index: -1;
      transform: translate(10px, -120px);

      @include screen-max(720px) {
        transform: translate(10px, -140px);
      }
    }

    h4 {
      text-align: left;
      padding-bottom: 15px;
      color: var(--primary-color);
      border-bottom: 1px solid #16a5a345;
      font-size: 20px;

      @include screen-max(720px) {
        margin-left: 0px;
        font-size: 16px;
        padding-bottom: 5px;
        margin-bottom: 20px;
      }
    }

    padding-bottom: 45px;

    .card-columns {
      padding: 0 40px 0 40px;
      justify-content: start;
      margin: auto;

      @include screen-max(720px) {
        padding: 10px;
        margin: 0px;
        column-gap: 20px;
      }
    }

    .activity {
      display: block;
      background-color: white !important;
      margin-top: 15px;
      height: 180px !important;
      padding: 0px;
      margin: 15px;
      width: 100%;
      flex-direction: row;
      box-shadow: 0 0 7px -5px black;

      @include screen-max(720px) {
        height: 140px !important;
        width: 100%;
        margin: 0px;
        column-gap: 10px;
        // margin-bottom: 20px;
      }

      a {
        color: black;
        text-decoration: none;
      }

      .img {
        @include screen-max(720px) {
          padding-right: 0px;
        }

        .pills {
          transform: translate(-9px, 18px);
          width: max-content;
          position: relative;
          z-index: 999;
          background-color: var(--primary-color);

          @include screen-max(1200px) {
            transform: translate(-9px, 10px);
            width: max-content;
            position: relative;
            z-index: 999;
            background-color: var(--primary-color);
          }

          svg {
            transform: translate(0px, -17px);

            @include screen-max(1200px) {
              transform: translate(0px, -12.5px);
            }
          }

          span {
            text-align: center;
            transform: translate(-8px, 0px);
            display: inline-block;
            color: white;
            text-transform: uppercase;
          }
        }
      }

      .description {
        @include screen-max(720px) {
          padding-left: 0px;
        }

        svg {
          margin-right: 5px;
        }

        .distance {
          font-size: 15px;

          @include screen-max(720px) {
            font-size: 10px;
          }
        }
      }

      .Activity {
        font-weight: 700;
        color: var(--secondary-color);
        transform: translate(0px, -73px);
        margin-left: 420px;
        width: 673px;
        text-align: left;
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
        gap: 5px;

        @include screen-max(1200px) {
          width: 373px;
          margin-left: 255px;
          text-align: center;
        }

        @include screen-max(820px) {
          width: 350px;
          margin-left: 290px;
          text-align: center;
        }

        @include screen-max(720px) {
          margin-left: 10px;
          transform: translate(0px, -15px) !important;
          text-align: left !important;
        }

        .p-1 {
          padding: 0 5px !important;
        }
      }

      margin-bottom: 30px;
      border: none;

      .cardImg-top {
        transform: translate(0px, -24px);

        overflow: hidden;
        height: 180px;
        width: 100%;
        border-radius: 5px 0px 0px 5px;

        @include screen-max(1200px) {
          transform: translate(0px, -18px);
        }

        @include screen-max(720px) {
          width: 100%;
          height: 115px;
          margin: 0px;
          transform: translate(0px, -18px);
          border-radius: 5px 0px 0px 0px;
        }
      }

      .box-shadow {
        height: 287px;
      }

      .box-shadow::after {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        box-shadow: inset 0 0 10px 10px #ffffff59, 0 0 39px -31px black;
        overflow: hidden;
        border-radius: 5px;
      }

      .detailPrice {
        background-color: transparent;
        width: 100%;
        height: 1px;
        display: -webkit-box;
        justify-content: end;
        -webkit-box-pack: end;
        /* justify-content on Safari*/
        -webkit-box-align: end;

        .price {
          background-color: white;
          width: max-content;
          padding: 0px 5px 5px 15px;
          border-radius: 10px 0 0 0;
          transform: translate(0px, 14px);
          height: max-content;
          display: flex;
          flex-direction: row;
          flex-wrap: nowrap;
          align-content: center;
          justify-content: center;
          align-items: center;

          @include screen-max(720px) {
            transform: translate(0px, 10px);
            padding: 2px 5px 2px 10px;
            font-size: 18px !important;
          }

          h3 {
            font-size: 20px !important;

            @include screen-max(720px) {
              font-size: 18px !important;
            }
          }

          .asterisk {
            color: var(--primary-color);
            font-size: 30px;
            transform: translate(0px, -10px);
          }
        }
      }

      .card-header {
        border: none;
        background-color: white;
        padding: 1rem 0.5rem 0rem 0.5rem;

        @include screen-max(720px) {
          padding: 0rem 0.5rem 0rem 0.5rem;
        }
      }

      .CardCategory {
        font-size: 15px;
        margin-top: 5px;
      }

      .card-title {
        font-size: 18px;
        text-align: left;
        background: white;
        // padding-top: 9px;
        // white-space: nowrap;
        width: 750px;
        // overflow: hidden;
        // text-overflow: ellipsis;
        margin: 0;
        margin-top: 10px;
        margin-bottom: 10px;

        @include screen-max(1200px) {
          width: 100%;
        }

        @include screen-max(720px) {
          width: 175px !important;
          margin-top: 5px;
          margin-bottom: 5px;
        }
      }

      .activityBody {
        padding: 0rem !important;
        background-color: white;
        color: #909090;
        margin-bottom: 15px;

        .description {
          display: -webkit-box;
          display: flex;
          flex-direction: column;
          margin: 0 !important;
          text-align: left;

          @include screen-max(720px) {
            display: none;
          }

          svg {
            width: 15px;
            height: 15px;
          }

          .location,
          .duration {
            display: flex;
            flex-direction: row;
            flex-wrap: nowrap;
            align-content: center;
            align-items: center;
            margin: 5px 0px;
          }

          .distance {
            margin-left: 20px;
            margin-bottom: 10px;
          }
        }

        .description_mobile {
          margin: 0 !important;
          text-align: left;

          .duration {
            margin-top: 5px;
          }

          .distance {
            margin-left: 12px;
          }
        }
      }

      .card-footer {
        border: none;
        background-color: transparent;
        padding-bottom: 17px;
        border-radius: 0 0 5px 5px;
        position: relative;
        z-index: 24;

        @include screen-max(720px) {
          padding: 0px;
          padding-bottom: 10px;
          // background-color: white;
        }

        .AddBtn-container {
          z-index: 999;

          @include screen-max(1200px) {
            display: none;
          }

          h3 {
            margin: 0;
            font-size: 25px;
          }

          justify-content: end;
          width: auto;
          align-items: center;

          .col {
            text-align: right;

            @include screen-max(720px) {
              text-align: center;
            }
          }

          .AddBtn {
            @include screen-max(720px) {
              padding: 2px 6px;
              font-size: 12px;
            }

            border: none;
            color: white;
            background-color: var(--primary-color);
            transform: translate(-5px, -40px);

            width: fit-content;

            i {
              font-size: 15px;
            }
          }
        }
      }
    }

    .activity:hover {
      box-shadow: 0 0 10px -5px black;
      border-radius: 15px;
    }
  }
}

//// aroundhoster/OfferDetails.jsx
.service {
  display: flex;
}

.OfferSection {
  display: block;
  margin-top: 25px;
  // padding: 15px;
  background-color: var(--background-color);
  color: var(--text-color);

  @include screen-max(1200px) {
    margin-top: 10px;
  }

  a {
    margin-left: 15px;
    margin-bottom: 15px;
    display: flex;
    justify-self: flex-start;
    align-items: center;
    column-gap: 5px;
    text-decoration: none;
    color: var(--text-color);
  }

  .back {
    margin-left: 15px;
    margin-bottom: 15px;
    display: flex;
    justify-self: flex-start;
    align-items: center;
    column-gap: 5px;
    background-color: transparent;
    border: none;
    color: var(--text-color);

    @include screen-max(1200px) {
      margin: 0px;
      background-color: var(--background-color);
      position: fixed;
      width: 100vw;
      z-index: 1200;
      top: 0px;
      height: 45px;
    }
  }

  .customBtn {
    display: inline !important;
    color: var(--text-color) !important;
  }

  .cardImg-top {
    @include screen-max(1200px) {
      height: 210px;
    }

    height: 500px;
    border-radius: 5px 5px 0 0;
  }
}

.bbody {
  padding: 0px 50px 0px 50px;
  margin-bottom: 80px !important;

  @include screen-max(1200px) {
    padding: 15px 0px;
    // transform: translate(0px, -15px);
  }

  figure {
    @include screen-max(1200px) {
      height: 400px;
    }

    @include screen-max(720) {
      height: 200px;
    }

    height: 500px;
    border-radius: 5px 5px 0 0;
  }

  .carousel {
    padding-left: 0px;
    padding-right: 0px;

    .carousel-control-prev,
    .carousel-control-next {
      margin-left: 0;
      filter: drop-shadow(1px 1px 0px black);
    }

    .carousel-item {
      figure {
        @include screen-max(1200px) {
          height: 400px;
        }

        @include screen-max(720) {
          height: 200px;
        }

        height: 500px;
        border-radius: 5px 5px 0 0;
      }
    }

    .carousel-indicators [data-bs-target] {
      background-color: var(--text-color);
      width: 8px;
      height: 8px;
      border-radius: 50%;
    }

    .carousel-indicators {
      bottom: -43px;
    }
  }

  .pills {
    background-color: var(--primary-color);
    color: white;
    text-transform: uppercase;
    width: max-content;
    height: fit-content;
    border-radius: 5px;
    padding: 2px 8px;
    transform: translate(10px, -54px);
  }

  .sectionOffer {
    padding: 0px;
    margin: 0 10px 0 0;
    transform: translate(-140px, 0px);

    @include screen-max(1200px) {
      width: 100%;
      margin: 0px;
      transform: translate(0px, 0px);
    }

    .infoOffer {
      padding: 15px;
      // padding-left: 0px;
      text-align: left;

      .titleLegend {
        font-size: 1rem;
        font-weight: 500;
      }

      .title {
        font-weight: 600;
        font-size: 1.6rem;
        letter-spacing: 0;
        line-height: 1.16;
        white-space: nowrap;
        width: 48ch;
        overflow: hidden;
        margin-bottom: 10px;

        @include screen-max(1200px) {
          font-size: 20px;
          width: 100%;
          overflow: unset;
          white-space: unset;
          font-weight: 600;
        }
      }
    }

    .itemDetail {
      margin-bottom: 20px;

      span {
        /*description*/
        font-weight: 300;
        white-space: break-spaces;
      }

      h4 {
        font-size: 18px;

        @include screen-max(1200px) {
          font-size: 15px;
          font-weight: 700 !important;
        }
      }

      .Offer {
        text-align: center;
        margin: 25px;

        @include screen-max(1200px) {
          margin-bottom: 10px;
          margin-top: 0px;
          // transform: translate(0px, -15px);
        }

        span {
          font-size: 25px;
          font-weight: 700;
          color: var(--secondary-color);

          @include screen-max(1200px) {
            font-size: 15px !important;
          }
        }
      }

      .localisation {
        a {
          margin: 0;
          margin-bottom: 15px;
        }

        .googlemap a {
          height: 450px;
          width: 450px;

          @include screen-max(850px) {
            width: auto;
          }
        }
      }
    }
  }

  .Infos {
    padding: 0px;
    padding-bottom: 20px;
    // margin: 15px 0 0 10px;
    background-color: #16a5a30f;
    margin: 30px 10px !important;
    margin-bottom: 40px !important;
    border: 1px solid var(--primary-color);
    text-align: left;
    border-radius: 5px;
    box-shadow: 0 0px 12px -7px black;
    // height: 194px;
    height: max-content;
    // @include screen-max(820px) {
    //     height: 134px;
    // }

    h4 {
      @include screen-max(1200px) {
        font-size: 15px !important;
        font-weight: 700 !important;
      }
    }

    .title {
      font-size: 18px;
      background-color: var(--background-color);
      transform: translate(-6px, -26px);
      display: block;
      font-weight: 500 !important;
      width: max-content;
      height: max-content;
      padding: 0px 5px;
      margin-bottom: 0px;
      white-space: nowrap;

      @include screen-max(1200px) {
        transform: translate(-5px, -26px);
        font-size: 25px;
        font-weight: 700 !important;
      }
    }

    .description {
      padding: 0px 15px 30px 15px;
      display: flex;
      flex-direction: column;

      @include screen-max(1200px) {
        padding: 0px 10px 0px 10px;
        margin-bottom: 15px !important;
      }

      span {
        font-weight: 300;
      }

      svg {
        @include screen-max(1200px) {
          width: 10px;
          height: 10px;
          margin-right: 5px;
        }

        margin-right: 10px;
        width: 15px;
        height: 15px;

        .customStroke {
          stroke: var(--text-color);
        }

        .customFill {
          fill: var(--text-color);
        }
      }

      .distance {
        font-size: 10px;
      }

      margin: 0 !important;
      text-align: left;

      .localisation,
      .telephone,
      .email,
      .open {
        display: inline;
        margin-bottom: 8px;

        span {
          font-weight: 300;
        }

        a {
          font-weight: 300;
          text-decoration: none;
          margin: 0;
        }
      }

      .openingHours {
        padding: 0 23px;

        @include screen-max(850px) {
          padding: 0 15px;
        }

        li {
          list-style: none;
          display: flex;
          justify-content: space-between;
        }
      }

      .open {
        display: flex;
        // @media only screen and (-webkit-min-device-pixel-ratio: 1.5),
        //     only screen and (min-device-pixel-ratio: 1.5) {
        //     svg {
        //         width: 18px;
        //         height: 18px;
        //     }
        // }
      }
    }

    .BtnContainer {
      @include screen-max(1200px) {
        flex-wrap: nowrap;
        margin: auto;

        .col {
          padding: 0px;
        }
      }

      .btn-book {
        font-size: 15px;
        width: fit-content;
        white-space: nowrap;
        border-radius: 5px !important;
        background-color: var(--primary-color) !important;

        @include screen-max(820px) {
          font-size: 12px;
        }
      }
    }

    @include screen-max(1200px) {
      margin: 0px;

      .modal-header {
        display: none;
      }

      .booking-title {
        font-size: 16px !important;
        padding: 5px;
        background-color: white;
        position: relative;
        top: 21px;
        left: 23px;
      }
    }
  }

  .BtnContainer {
    @include screen-max(1200px) {
      flex-wrap: nowrap;
      margin: auto;

      .col {
        padding: 0px;
      }
    }

    .customBtn {
      font-size: 20px;
      width: fit-content;
      white-space: nowrap;
      border-radius: 5px !important;
      background-color: var(--secondary-color) !important;
      color: white !important;

      @include screen-max(1200px) {
        font-size: 18px;
      }
    }
  }
}

////aroundhoster/index.jsx

.warning-empty {
  width: 100%;
  height: 100%;
  text-align: center;
  margin: auto;
  vertical-align: middle;
  display: flex;
  flex-wrap: nowrap;
  align-content: center;
  justify-content: center;
  align-items: center;

  @include screen-max(820px) {
    position: absolute;
    padding: 25px;
  }
}

//// aroundhoster/ActivityDetails.jsx
.service {
  display: flex;
}

.ActivitySection {
  display: block;
  margin-top: 25px;
  // padding: 15px;
  background-color: var(--background-color);
  color: var(--text-color);

  @include screen-max(820px) {
    margin-top: 10px;
  }

  .back {
    margin-left: 15px;
    margin-bottom: 15px;
    display: flex;
    justify-self: flex-start;
    align-items: center;
    column-gap: 5px;
    background-color: transparent;
    border: none;
    color: var(--text-color);

    @include screen-max(1200px) {
      background-color: var(--background-color);
      z-index: 999;
      width: 100vw;
      height: 45px;
      position: fixed;
      top: 0;
      right: 0;
      /* left: 0; */
      padding: 10px;
      text-decoration: none;
      color: var(--text-color);
    }
  }

  button {
    margin-left: 15px;
    margin-bottom: 15px;
    display: flex;
    justify-self: flex-start;
    align-items: center;
    column-gap: 5px;
    text-decoration: none;
    color: black;
  }

  a {
    color: var(--text-color);
    text-decoration: none;
  }

  .customBtn {
    display: inline !important;
    color: white !important;
  }

  .cardImg-top {
    @include screen-max(1200px) {
      height: 210px;
    }

    height: 500px;
    border-radius: 5px 5px 0 0;
  }

  .bbody {
    padding: 0px 50px 0px 50px;
    margin-bottom: 40px !important;

    @include screen-max(1200px) {
      padding: 50px 0px;
      // transform: translate(0px, -15px);
    }

    .carousel {
      padding-left: 0px;
      padding-right: 0px;

      .carousel-control-prev,
      .carousel-control-next {
        margin-left: 0;
        filter: drop-shadow(1px 1px 0px black);
      }

      .carousel-item {
        figure {
          @include screen-max(1200px) {
            height: 400px;
          }

          @include screen-max(720) {
            height: 200px;
          }

          height: 500px;
          border-radius: 5px 5px 0 0;
        }
      }

      .carousel-indicators [data-bs-target] {
        background-color: var(--text-color);
        width: 8px;
        height: 8px;
        border-radius: 50%;
      }

      .carousel-indicators {
        bottom: -43px;
      }
    }

    .pills {
      background-color: var(--primary-color);
      color: white;
      text-transform: uppercase;
      width: max-content;
      height: fit-content;
      border-radius: 5px;
      padding: 2px 8px;
      transform: translate(10px, -54px);
    }

    .ActivityBody {
      padding: 0px;
      margin: 0px;

      .sectionActivity {
        padding: 0px;
        margin: 0 10px 0 0;

        // transform: translate(-130px, 0px);
        @include screen-max(820px) {
          margin: 0px;
          transform: translate(0px, -20px);
        }

        .infoActivity {
          padding: 15px;
          // padding-left: 0px;
          text-align: left;

          .titleLegend {
            font-size: 1rem;
            font-weight: 500;
          }

          .title {
            font-weight: 600;
            font-size: 1.6rem;
            letter-spacing: 0;
            line-height: 1.16;
            white-space: nowrap;
            width: 48ch;
            overflow: hidden;
            padding-bottom: 30px;

            @include screen-max(820px) {
              margin-bottom: 20px;
              font-size: 20px;
              width: 100%;
              overflow: unset;
              white-space: unset;
              font-weight: 600;
            }
          }
        }

        .itemDetail {
          margin-bottom: 20px;

          .localisation,
          .duration {
            svg {
              width: 12px;
              height: 12px;
              margin-right: 5px;

              .customFill {
                fill: var(--text-color);
              }
            }

            a {
              margin: 0;
              margin-bottom: 15px;
            }
          }

          span {
            /*description*/
            font-weight: 300;
            white-space: break-spaces;
          }

          h4 {
            font-size: 18px;

            @include screen-max(820px) {
              font-size: 15px;
              font-weight: 700 !important;
            }
          }

          .googlemap {
            height: 450px !important;

            @include screen-max(820px) {
              height: 250px !important;
            }
          }
        }
      }

      .Infos {
        @include screen-max(820px) {
          display: none;
        }

        display: flex;
        flex-direction: column;

        align-content: center;
        flex-wrap: nowrap;
        justify-content: center;
        align-items: center;
        padding: 10px 25px;
        background-color: #16a5a30f;
        margin: 30px 10px !important;
        margin-bottom: 40px !important;
        border: 1px solid var(--primary-color);
        text-align: left;
        border-radius: 5px;
        box-shadow: 0 0px 12px -7px black;
        height: 200px;

        .row {
          align-content: center;
        }

        .price {
          color: var(--text-color);
          text-align: center;
          margin-bottom: 15px;
          display: flex;
          flex-direction: row;
          flex-wrap: nowrap;
          align-content: center;
          justify-content: center;
          align-items: center;

          @include screen-max(820px) {
            text-align: left;
            line-height: 0.5;
            margin-bottom: 0px;
          }

          span {
            font-size: 12px !important;

            @include screen-max(820px) {
              font-size: 10px !important;
            }
          }

          h3 {
            font-size: 40px !important;
            margin: auto !important;
            color: var(--secondary-color);

            @include screen-max(820px) {
              font-size: 30px !important;
            }
          }

          .asterisk {
            color: var(--primary-color);
            font-size: 30px;
            transform: translate(0px, -10px);
          }
        }

        .btn-book {
          margin-left: 0px;
          font-size: 15px;
          width: fit-content;
          white-space: nowrap;
          border-radius: 5px !important;
          background-color: var(--primary-color) !important;

          @include screen-max(820px) {
            font-size: 12px;
          }
        }

        @include screen-max(820px) {
          margin: 0px;

          .modal-header {
            display: none;
          }

          .booking-title {
            font-size: 16px !important;
            padding: 5px;
            background-color: white;
            position: relative;
            top: 21px;
            left: 23px;
          }
        }
      }
    }

    .BtnContainer {
      @include screen-max(820px) {
        flex-wrap: nowrap;
        margin: auto;

        .col {
          padding: 0px;
        }
      }

      .customBtn {
        font-size: 20px;
        width: fit-content;
        white-space: nowrap;
        border-radius: 5px !important;
        background-color: var(--secondary-color) !important;

        @include screen-max(820px) {
          font-size: 18px;
        }
      }
    }
  }

  .custom_footer_details {
    box-shadow: rgba(0, 0, 0, 0.1) 0px 0px 10px 10px;
    width: 100vw;
    background-color: white;
    display: none;
    flex-direction: row;
    position: fixed;
    z-index: 4000;
    bottom: 0px;
    align-content: center;
    flex-wrap: nowrap;
    justify-content: center;
    align-items: center;
    padding: 10px 25px;

    @include screen-max(1200px) {
      display: flex;
    }

    .price {
      line-height: 0.5;
      display: flex;
      flex-direction: row;
      flex-wrap: nowrap;
      align-content: center;
      justify-content: center;
      align-items: center;

      span {
        font-size: 10px !important;
      }

      h3 {
        font-size: 30px !important;
        margin: auto !important;
        color: var(--secondary-color);

        @include screen-max(820px) {
          transform: translate(0px, 2px);
        }
      }

      .asterisk {
        color: var(--primary-color);
        font-size: 30px;
        transform: translate(0px, -10px);
      }
    }

    a {
      white-space: nowrap;
    }
  }
}

////views/roomdirectory/WelcomModal.jsx

.welcomeModal {
  display: flex;
  width: 100% !important;
  // height: 100%;
  padding: 40px;

  img {
    width: 100%;
  }
}

div[tabindex="-1"] {
  z-index: 5500 !important;
}

.modal {
  display: flex !important;
  z-index: 5000;

  .modal-dialog {
    margin: auto;
    width: 300px;

    .modal-content {
      .modal-body {
        text-align: center;
        padding: 3rem 2rem;
        font-weight: 300;
        color: grey;

        h3 {
          color: var(--primary-color);
          font-size: 15px !important;

          span {
            font-size: 15px !important;
            color: var(--secondary-color);
          }
        }

        p {
          margin: 15px 0px;
        }

        .customBtn {
          background-color: var(--secondary-color) !important;
        }
      }
    }
  }
}

.modal-backdrop {
  backdrop-filter: blur(3px);
  background-color: #0006;
  opacity: 1 !important;
}

//components/roomdirectory/Modules.jsx
._module {
  margin: 15px 0px;
  padding: 0px;
  color: black;

  figure {
    width: 100%;
    overflow: hidden;
    height: auto;
    margin: 0px 0px 4px 0px;

    img {
      width: 100%;
      height: auto;
    }
  }

  img {
    max-width: 100%;
  }

  .AddBtn {
    background-color: var(--secondary-color);
    color: white;
    border: none;
    box-shadow: 0 0 8px -4px black;
    font-size: 14px;
    width: clamp(100%, 100%, 45px);

    svg {
      width: auto;
      height: 18px;
      margin-right: 5px;
    }
  }

  a {
    text-align: center;
    color: var(--secondary-color);
    text-decoration: none;
  }

  td {
    border: 1px solid black;
  }

  .googlemap {
    height: 200px !important;
  }

  label {
    text-align: center;
    margin: auto;

    svg {
      max-height: 25px;
      max-width: 25px;
      height: 25px;
      width: 10px;
    }
  }

  .viewerPdf-Body {
    // height: 100% !important;
    // overflow-y: auto;
    // padding-bottom: 15px;
    height: max-content;
    overflow-y: hidden;
    padding-bottom: 0px;
  }

  .page-list .react-pdf__Page canvas {
    width: 100% !important;
  }

  .pdf-controls {
    display: none;
  }

  .socialmedias {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-content: center;
    justify-content: center;
    align-items: center;
    margin: 0;

    a {
      width: max-content;

      button {
        width: max-content;
        border: none;
        padding: 0px;
        background-color: white;

        svg {
          height: 18px;
          width: auto;

          .customFill {
            fill: var(--primary-color);
          }

          .customStroke {
            stroke: var(--primary-color);
          }
        }
      }
    }
  }

  @include screen-max(820px) {
    h3 {
      font-size: 15px !important;
    }
  }

  h3 {
    font-size: 20px !important;
    text-align: center;
    color: var(--secondary-color);
  }

  .embed-youtube {
    iframe {
      width: 100%;
      height: 520px;

      @include screen-max(820px) {
        height: 170px;
      }
    }
  }
}

///// a definir
// .main__roomdirectory::before {
//     content: "";
//     height: 100vh;
//     width: 100vw;
// }
.bg-imagecl {
  /* The image used */
  // background-image: url(../images/citylodges2.jpg);
  // background-image: url("photographer.jpg");
  background-color: var(--background-color);
  position: fixed;
  z-index: 1;
  // /* Add the blur effect */
  // filter: blur(5px);
  // -webkit-filter: blur(5px);

  /* Full height */
  height: 100vh;
  width: 100vw;

  /* Center and scale the image nicely */
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.main__roomdirectory {
  position: relative;
  z-index: 100;
  margin-bottom: 80px;
  margin-top: 100px;
  padding: 0px 150px;

  @include screen-max(1200px) {
    margin-top: 0px;
    padding: 0px;
  }

  .messageContent {
    margin: 15px 20px !important;
    padding: 15px;
    background-color: var(--primary-color);
    border-radius: 10px;

    &__label {
      font-size: 16px !important;
    }

    @include screen-max(1200px) {}
  }

  .message {
    color: white;
    text-decoration: none;
    display: flex;
    justify-content: space-between;
  }
}

.host__infos {
  margin-bottom: 20px;
  display: none;

  @include screen-max(1200px) {
    display: block;
  }

  figure {
    width: 150px;
    height: 150px;
    overflow: hidden;
    margin: auto;
    // background-color: white;
    /* height: 90px; */
    /* width: 90px; */
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    align-content: center;
    justify-content: center;
    align-items: center;
    // border-radius: 50%;
    padding: 5px;

    img {
      width: 100%;
      height: auto;
      max-height: 100%;
      max-width: 100%;
    }
  }

  h5 {
    // display: none;
    text-align: center;
    color: var(--text-color);
    margin-top: 15px;
  }
}

//// components/roomdirectoy/Categories.jsx

.category {
  margin: 15px 20px !important;
  box-shadow: 0 0 10px -5px var(--text-color);

  border: none;
  border-radius: 10px;
  background-color: white;

  .header_card {
    // background-color: rgb(from var(---secondary-color) / 50%);
    height: 70px;
    display: flex;
    flex-wrap: nowrap;
    align-content: center;
    justify-content: space-between;
    align-items: center;
    padding: 15px;

    svg {
      fill: black !important;
    }
  }

  .target_marker {
    transform: translate(0px, -70px);
  }

  .card-header {
    border: none;
    height: 70px;
    background-color: white;
    padding: 0px;
    border-radius: 10px;

    // position: sticky;
    // top: 0px;
  }

  .header_card::before {
    content: "";
    width: 100%;
    height: 70px;
    position: absolute;
    left: 0;
    top: 0;
    background-color: var(--primary-color);
    opacity: 0.15;
    // box-shadow: 0 0 5px 0px #0000002b;
    border: none;
    border-radius: 10px;
    // position: sticky;
    // top: 0px;
  }
}

.category.opened {

  // background-color: green;
  .card-body {
    background-color: white;
  }

  .card-header {
    // border: none;
    position: sticky;
    top: 0px;
    z-index: 10000000;
    height: 100%;
  }

  .header_card,
  .header_card::before {
    border-radius: 10px 10px 0px 0px;
    height: 100%;
    min-height: 70px;
  }

  .category__infos {

    h4,
    i {
      width: 100%;
      white-space: break-spaces;
    }
  }
}

.inlineIcon {
  width: 100%;
  display: flex;
  flex-wrap: nowrap;
  justify-content: flex-start;
  // align-items: center;
  align-content: center;
  flex-direction: row;
  color: black;

  .col-md-1 {
    padding: 0;
    max-width: 30px !important;
    margin: 0 auto;
    margin-right: 15px;

    // svg {
    //     .customStroke{
    //         stroke: white;
    //     }
    // }
    @include screen-max(820px) {
      margin-right: 5px;
    }
  }

  .category__infos {
    padding: 0 !important;
  }

  svg {
    width: auto;
    height: 25px;
    margin: 0px 10px 0px 10px;
    position: relative;
    max-height: 20px;
    max-width: 20px;

    @include screen-max(820px) {
      max-width: 15px;
      max-height: 15px;
    }

    // top: -3px;
  }

  h4 {
    margin: 0;
    font-size: 20px;
    font-weight: 400;

    @include screen-max(820px) {
      font-size: 15px;
      font-size: 15px;
      width: 34ch;
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
    }
  }

  i {
    margin: 0;
    font-size: 11px;

    @include screen-max(820px) {
      display: block;
      width: 40ch;
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
    }
  }
}

//// Service/ServiceDetail.jsx

.service {
  display: flex;
}

.Service {
  // position: sticky;
  // top: 0px;
  margin-top: 25px;
  padding: 15px;
  background-color: var(--background-color);
  color: var(--text-color);

  @include screen-max(1200px) {
    margin-top: 0px;
    padding-bottom: 100px !important;
    padding: 0px;
    // height: 80vh;
    // overflow-y: scroll;
    // overflow-x: hidden;
    // -webkit-overflow-scrolling: touch;
    // display: block;
  }

  button {
    margin-left: 15px;
    margin-bottom: 15px;
    display: flex;
    justify-self: flex-start;
    align-items: center;
    column-gap: 5px;
    color: var(--text-color);
    position: relative;
    z-index: 1000;
    background-color: transparent;
    border: none;
  }

  .back {
    color: var(--text-color);
    text-decoration: none;

    @include screen-max(1200px) {
      // transform: translate(10px, 0);
      position: fixed;
      top: 25px;
      padding: 15px;
      background-color: #0000002b;
      border-radius: 50%;
      -webkit-backdrop-filter: blur(10px);
      backdrop-filter: blur(2px);

      svg {
        filter: drop-shadow(2px 0px 0px white);
        fill: var(--primary-color);
        stroke: var(--primary-color);
        stroke-width: 3px;
      }

      span {
        display: none;
      }
    }
  }

  .contentService {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    padding: 0;
    margin: 0;

    @include screen-max(1200px) {
      display: block;
    }

    .button-wrapper {
      @include screen-max(1200px) {
        display: none;
      }

      text-align: center;
      vertical-align: middle;
      margin: auto;

      .customBtn {
        background-color: var(--secondary-color) !important;
        width: fit-content;
        white-space: nowrap;
        border-radius: 5px !important;
        margin: auto;
        color: white;

        @include screen-max(820px) {
          padding: 8px 15px;
          font-size: 15px;
        }
      }
    }
  }

  .bbody {
    padding: 0px;

    @include screen-max(1200px) {
      transform: translate(0px, -30px);
      margin-bottom: 15px !important;
      // margin-bottom: 90px !important;
      // overflow-y: scroll;
      // height: 100vh;
    }

    .m-0 {
      @include screen-max(820px) {
        padding: 0 0;
      }
    }

    .detailPrice {
      background-color: transparent;
      width: 100%;
      height: 1px;
      display: -webkit-box;
      justify-content: end;
      -webkit-box-pack: end;
      /* justify-content on Safari*/
      -webkit-box-align: end;

      .price {
        background-color: var(--background-color);
        width: max-content;
        padding: 5px 5px 5px 15px;
        border-radius: 10px 0 0 0;
        transform: translate(-30px, -4px);
        height: max-content;
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
        align-content: center;
        justify-content: center;
        align-items: center;

        @include screen-max(820px) {
          transform: translate(13px, 10px);
          padding: 2px 5px 2px 10px;
        }

        h3 {
          color: var(--text-color);
          font-size: 20px !important;

          @include screen-max(820px) {
            font-size: 25px !important;
          }
        }

        .asterisk {
          color: var(--primary-color);
          font-size: 30px;
          transform: translate(0px, -10px);
        }
      }
    }

    .cardImg-top {
      height: 550px;

      border-radius: 5px 5px 0 0;

      @include screen-max(1200px) {
        height: 450px;
        margin: 0px;
      }
    }

    .sectionService {
      padding: 0px;
      margin: 0 10px 0 0;

      @include screen-max(1200px) {
        margin: 0px;
        padding: 0px 15px;
      }

      .infoService {
        padding: 15px 15px 0px 0px;
        padding-left: 0px;
        text-align: left;

        .headerService {
          flex-direction: row;
          display: flex;
          flex-wrap: nowrap;
          align-content: center;
          justify-content: flex-start;
          align-items: center;

          .title {
            font-weight: 500;
            font-size: 1.6rem;
            letter-spacing: 0;
            line-height: 1.16;
            // white-space: nowrap;
            width: 42ch;
            overflow: hidden;
            margin-bottom: 10px;

            @include screen-max(820px) {
              font-size: 20px;
              width: 100%;
              overflow: unset;
              // white-space: unset;
              font-weight: 700;
            }
          }

          .detailPrice {
            height: max-content;

            @include screen-max(1200px) {
              display: none;
            }
          }

          .priceWrapper {
            // position: relative;
            // top: -78px;
            // right: -16px;
            display: -webkit-box;
            justify-content: end;
            -webkit-box-pack: end;
            /* justify-content on Safari*/
            -webkit-box-align: end;
            /* align-items on Safari */

            @include screen-max(820px) {
              top: -69px;
              right: -15px;
            }

            .price {
              display: flex;
              flex-direction: row;
              flex-wrap: nowrap;
              align-content: center;
              justify-content: center;
              align-items: center;

              h3 {
                font-size: 35px !important;
              }

              color: var(--primary-color);
              font-weight: 500;

              @include screen-max(820px) {
                font-size: 25px !important;
              }

              .asterisk {
                color: var(--primary-color);
                font-size: 30px;
                transform: translate(0px, -10px);
              }
            }
          }
        }
      }

      .asterisk-details {
        color: var(--primary-color);
        font-size: 12px;
        transform: translate(0px, -10px);

        @include screen-max(1200px) {
          font-size: 11px;
        }

        p {
          display: flex;
          flex-direction: row;
          flex-wrap: nowrap;
          align-content: center;
          // justify-content: center;
          align-items: center;
          gap: 5px;
        }
      }

      .itemDetail {

        // margin-bottom: 20px;
        span {
          /*description*/
          font-weight: 100;
          white-space: break-spaces;

          a {
            color: var(--primary-color);
            text-decoration: none;
          }

          a:hover {
            color: var(--secondary-color);
          }
        }

        .recap {
          margin: 15px 0px 0px 0px;
          display: flex;
          flex-direction: column;

          span {
            margin: 5px 0px;
            display: flex;

            figure {
              width: 30px;
              margin: 0px;
              // background-color: green;
              text-align: left;

              svg {
                // margin-right: 5px;
                width: 20px;
                height: 20px;
              }
            }
          }
        }
      }
    }

    .bookingNotOrderable {
      @include screen-max(1200px) {
        display: none;
      }

      h5::before {
        content: "";
        width: 100%;
        height: 100%;
        position: absolute;
        left: 0;
        top: 0;
        background-color: var(--primary-color);
        opacity: 0.15;
        // box-shadow: 0 0 5px 0px #0000002b;
        border: none;
        // border-radius: 5px;
        // position: sticky;
        // top: 0px;
      }

      position: relative;
      background-color: transparent;
      padding: 40px;
      margin: 40px 0px;
      border: 1px solid var(--primary-color);
      text-align: center;
      border-radius: 5px;
      box-shadow: 0 10px 21px -12px black;
    }

    .bookingForm {
      padding: 0px;
      // margin: 15px 0 0 10px;
      background-color: white;
      margin: 40px 0px;
      border: 1px solid var(--primary-color);
      text-align: left;
      border-radius: 5px;
      box-shadow: 0 10px 21px -12px black;

      @include screen-max(1200px) {
        display: none;
      }

      .title {
        // background-color: white;
        color: black;
        // transform: translate(10px, 10px);
        display: block;
        font-weight: 400;
        font-size: 25px;
        transform: translate(15px, 50%);
        width: max-content;
        height: max-content;
        padding: 0px 5px;

        @include screen-max(820px) {
          display: none;
        }
      }

      .indicator {
        background-color: #16a5a3;
        height: 3px;
        margin: 0px 155px;
      }

      .infosForm {
        padding: 20px;
        // padding-top: 0px;
        color: black;

        @include screen-max(820px) {
          padding: 11px;
          padding-top: 25px;
          margin: 10px;
          border: 1px solid;
        }

        .form-label {
          color: black !important;
          font-size: 12px !important;

          @include screen-max(820px) {
            color: black !important;
            font-size: 10px !important;
            padding: 0.85rem 0.65rem;
          }
        }

        @include screen-max(820px) {
          padding: 11px;
          margin: 10px 10px 0px 10px;
        }

        h5 {
          color: var(--primary-color);
          font-size: 1.1rem;
        }
      }

      @include screen-max(820px) {
        .form-control {
          font-size: 10px !important;

          .mb-3 {
            font-size: 15px;
            font-weight: 100;

            @include screen-max(820px) {
              font-size: 12px;
            }
          }

          h5 {
            color: var(--primary-color);
            font-size: 1.1rem;
          }
        }
      }

      .BtnContainer {
        @include screen-max(820px) {
          .form-control {
            font-size: 10px !important;
          }
        }

        .customBtn {
          background-color: var(--secondary-color) !important;
          width: fit-content;
          white-space: nowrap;
          border-radius: 5px !important;
          margin: auto;
          color: white;

          @include screen-max(820px) {
            padding: 8px 15px;
            font-size: 15px;
          }
        }
      }

      .quantitySpinner {
        // width: 130px !important;
        display: flex;

        .input-group {
          align-items: center;
        }

        .form-control {
          height: 40px;
        }

        button {
          border: 1px solid gray;
          border-radius: 50%;
          width: 40px;
          height: 40px;
          margin: 10px;
        }
      }
    }
  }

  .orderableSection {
    display: none;
    position: fixed;
    z-index: 5000;
    bottom: 0px;
    background-color: var(--background-color);
    width: 100vw;
    height: 65px;
    box-shadow: var(--text-color) 0px 0px 10px -2px;
    flex-direction: row;
    flex-wrap: nowrap;
    align-items: center;
    justify-content: space-between;
    align-content: center;
    padding: 15px;

    @include screen-max(1200px) {
      display: flex;
    }

    .detailPrice {
      text-align: left;
      margin-right: 15px;

      .priceWrapper {
        .price {
          display: flex;
          flex-direction: row;
          flex-wrap: nowrap;
          align-content: center;
          justify-content: center;
          align-items: center;

          .asterisk {
            color: var(--primary-color);
            font-size: 30px;
            transform: translate(0px, -10px);
          }
        }

        #price {
          h3 {
            font-size: 25px !important;
          }
        }
      }
    }

    .infos {
      @include screen-min(720px) {
        br {
          display: none;
        }
      }
    }

    .redirection-btn {
      text-align: end;

      a {
        font-size: 14px;
      }

      @include screen-min(720px) {
        br {
          display: none;
        }
      }
    }
  }
}

.bookingFormMobile {
  // margin: 15px 0 0 10px;
  display: none;
  background-color: white;
  // margin: 40px 0px;
  // border: 1px solid $gwBlue;
  text-align: left;
  border-radius: 5px;
  // box-shadow: 0 -2px 21px -12px black;
  background-color: white;
  text-align: left;
  border-radius: 5px;

  // margin-bottom: 50px !important;

  @include screen-max(1200px) {
    display: block;
    // margin: 0px;
    // z-index: 4000;
    // padding: 0px;
    // // padding-bottom: 15px;
    // margin-top: -100px;

    .modal-header {
      display: none;
    }

    .booking-title {
      font-size: 17px !important;
      text-align: center;
      // margin-top: 25px;
      text-align: left;
      margin-left: 20px;
      margin-top: 15px;
      background-color: white;
      width: max-content;
      padding: 5px;
      transform: translate(0px, 20px);
    }
  }

  .title {
    background-color: white;
    display: block;
    font-weight: 400;
    font-size: 25px;
    transform: translate(15px, -50%);
    width: max-content;
    height: max-content;
    padding: 0px 5px;

    @include screen-max(820px) {
      display: none;
    }
  }

  .drawer-bottom {
    z-index: 4000;
    position: relative;
    background-color: white;
    // margin-top: 70px;
    padding: 5px 0px 20px 0px;
  }

  .mb-3 {
    font-size: 15px;
    font-weight: 100;

    @include screen-max(820px) {
      font-size: 10px;
    }
  }

  .indicator {
    background-color: #16a5a3;
    height: 3px;
    margin: 0px 155px;
  }

  .infosForm {
    padding: 20px;
    padding-top: 0px;
    border: 1px solid black;

    .form-label {
      font-size: 11px !important;
      color: black !important;
      font-weight: 400;
    }

    @include screen-max(1200px) {
      padding: 11px;
      margin: 10px 10px 0px 10px;
      margin-top: 0px !important;
      padding-top: 15px !important;
    }

    h5 {
      color: var(--primary-color);
      font-size: 1.1rem;
    }

    @include screen-max(1200px) {
      .form-control {
        font-size: 12px !important;
        padding: 0.85rem 0.65rem;
      }

      .form-select {
        font-size: 12px;
        padding: 0.85rem 0.65rem;
      }
    }

    .BtnContainer {
      @include screen-max(1200px) {
        flex-wrap: nowrap;
        margin: auto;

        .col {
          padding: 0px;
        }
      }

      .customBtn {
        background-color: var(--secondary-color) !important;
        width: fit-content;
        white-space: nowrap;
        border-radius: 5px !important;

        @include screen-max(820px) {
          padding: 8px 15px;
          font-size: 15px;
        }
      }
    }

    .quantitySpinner {
      // width: 130px !important;
      display: flex;

      .input-group {
        align-items: center;
      }

      .form-control {
        height: 40px;
      }

      button {
        border: 1px solid gray;
        border-radius: 50%;
        width: 40px;
        height: 40px;
        margin: 10px;
      }
    }
  }
}

//// components/booking-form/index.jsx
#BtmSheet {
  opacity: 1 !important;
}

.BtmSheet {
  opacity: 1 !important;
  --rsbs-content-opacity: 1 !important;
  --rsbs-backdrop-opacity: 1 !important;

  @include screen-min(1205px) {
    display: none;
  }

  div[role="dialog"] {
    z-index: 9000 !important;
  }

  div[data-rsbs-header] {
    // padding: 0;
    padding-top: calc(7px + env(safe-area-inset-top));
    box-shadow: unset;
  }

  div[data-rsbs-content] {
    overflow: scroll;
  }
}

.buttons_animate {
  display: flex;
  display: -webkit-box;
  align-content: end;
  justify-content: end;
  align-items: center;
  -webkit-box-pack: end;
  /* justify-content */
  -webkit-box-align: end;

  .switchBtn {
    //white-space: nowrap;
    white-space: break-spaces;
    min-width: 130px;
    animation-name: cssAnimation;
    animation-duration: 0.2s;
    animation-iteration-count: 1;
    animation-timing-function: ease;
    animation-fill-mode: forwards;
    -webkit-animation-name: cssAnimation;
    -webkit-animation-duration: 0.2s;
    -webkit-animation-iteration-count: 1;
    -webkit-animation-timing-function: ease;
    -webkit-animation-fill-mode: forwards;
  }

  .closeBtn {
    background: none;
    border: none;

    svg {
      fill: #747474;
    }
  }
}

.flashInfo {
  display: flex;
  background-color: white;
  // padding: 10px 20px 0px 20px;
  padding-top: 10px;
  align-items: center;
  // width: 100vw;
  // position: fixed;
  // bottom: 0px;
  // z-index: 1000;
  // margin: 0px !important;
  // box-shadow: 0 0 20px -19px black;

  .detailPrice {
    text-align: center;

    .priceWrapper {
      .price {
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
        align-content: center;
        justify-content: center;
        align-items: center;

        .asterisk {
          color: var(--primary-color);
          font-size: 30px;
          transform: translate(0px, -10px);
        }
      }

      #price {
        h3 {
          font-size: 20px !important;
        }
      }
    }
  }

  #info {
    padding: 0;
    align-items: center;
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    align-content: center;
    justify-content: center;
  }

  .infos {
    white-space: nowrap;
    font-size: 15px;
    font-weight: 100;

    @include screen-max(820px) {
      font-size: 10px;
      text-align: center;
    }
  }

  .btn-blue {
    font-size: 10px;
  }
}

//// pages/NotFound.jsx

.notfound {
  margin: auto;
  height: 100vh;
  justify-content: center;
  align-items: center;
  display: flex;
  flex-direction: column;

  .elements {
    text-align: justify;

    h1 {
      font-size: 63px;
    }

    a,
    a:active {
      color: var(--primary-color);
      text-decoration: none;
    }
  }
}

//// footer/index.jsx
.footer {
  padding-bottom: 15px;
  padding-top: 15px;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 0px 2px 0px;

  .socialmedias {
    flex-direction: row !important;
  }

  .footer-container {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }

  @include screen-min(320px) {
    text-align: left;
  }

  .title-h5 {
    @include screen-max(820px) {
      font-size: 14px;
    }
  }

  span {
    @include screen-max(820px) {
      font-size: 12px;
    }
  }
}

.textAlign-left {
  text-align: center;

  @include screen-max(820px) {
    text-align: left;
  }
}

//// pills
.bonplan {
  padding: 5px;
  position: absolute;
  top: 10px;
  left: 10px;
  background-color: var(--primary-color);
  color: #fff;
  width: fit-content;
  height: 25px;
  border-radius: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-transform: uppercase;
  font-size: 12px;
}

.bestseller {
  padding: 5px;
  position: absolute;
  top: 10px;
  left: 10px;
  background-color: var(--secondary-color);
  color: #fff;
  width: fit-content;
  height: 25px;
  border-radius: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-transform: uppercase;
  font-size: 12px;
}

//// roomdirectoy/Message.jsx
.mainMessageContainer .back {
  margin-left: 15px;
  margin-bottom: 15px;
  display: flex;
  justify-self: flex-start;
  align-items: center;
  column-gap: 5px;
  background-color: transparent;
  border: none;
  color: var(--text-color);
  z-index: 100;

  @include screen-min(1205px) {
    position: absolute;
    top: 30px;
  }

  @include screen-max(1200px) {
    margin: 0px;
    background-color: transparent;
    position: fixed;
    width: 100vw;
    z-index: 1200;
    top: 0px;
    height: 45px;
  }
}

.containerMessage::before {
  display: block;
  content: "";
  height: 100vh;
  width: 100%;
  background-color: var(--primary-color);
  opacity: 0.15;
  position: fixed;

  @include screen-max(1200px) {
    width: 100vw;
  }
}

.containerMessage {
  height: 100vh;
  width: 100%;
  display: flex;
  align-items: center;

  @include screen-max(1200px) {
    width: 100vw;
    display: block;
  }
}

.MessageFormMobile {
  background-color: transparent;
  text-align: left;
  border-radius: 5px;
  text-align: left;
  border-radius: 5px;
  position: relative;
  z-index: 100;
  top: 55px;
  padding: 0px 15%;

  @include screen-max(1200px) {
    padding: 0px 0px;
  }

  h3 {
    margin: 0px 0px 10px 10px;
  }

  @include screen-max(1200px) {
    display: block;

    .modal-header {
      display: none;
    }

    .booking-title {
      font-size: 17px !important;
      text-align: center;
      // margin-top: 25px;
      text-align: left;
      margin-left: 20px;
      margin-top: 15px;
      background-color: white;
      width: max-content;
      padding: 5px;
      transform: translate(0px, 20px);
    }
  }

  .title {
    background-color: white;
    display: block;
    font-weight: 400;
    font-size: 25px;
    transform: translate(15px, -50%);
    width: max-content;
    height: max-content;
    padding: 0px 5px;

    @include screen-max(820px) {
      display: none;
    }
  }

  .drawer-bottom {
    z-index: 4000;
    position: relative;
    background-color: white;
    // margin-top: 70px;
    padding: 5px 0px 20px 0px;
  }

  .mb-3 {
    font-size: 15px;
    font-weight: 100;

    @include screen-max(820px) {
      font-size: 10px;
    }
  }

  .indicator {
    background-color: #16a5a3;
    height: 3px;
    margin: 0px 155px;
  }

  .infosForm {
    padding: 20px;

    // padding-top: 0px;
    // border: 1px solid black;
    .form-label {
      font-size: 11px !important;
      color: black !important;
      font-weight: 400;
    }

    @include screen-max(1200px) {
      padding: 11px;
      margin: 10px 10px 0px 10px;
      margin-top: 0px !important;
      padding-top: 15px !important;
    }

    h5 {
      color: var(--primary-color);
      font-size: 1.1rem;
    }

    @include screen-max(1200px) {
      .form-control {
        font-size: 12px !important;
        padding: 0.85rem 0.65rem;
      }

      .form-select {
        font-size: 12px;
        padding: 0.85rem 0.65rem;
      }
    }

    .BtnContainer {
      @include screen-max(1200px) {
        flex-wrap: nowrap;
        margin: auto;

        .col {
          padding: 0px;
        }
      }

      .customBtn {
        background-color: var(--secondary-color) !important;
        width: fit-content;
        white-space: nowrap;
        border-radius: 5px !important;

        @include screen-max(820px) {
          padding: 8px 15px;
          font-size: 15px;
        }
      }
    }

    .quantitySpinner {
      // width: 130px !important;
      display: flex;

      .input-group {
        align-items: center;
      }

      .form-control {
        height: 40px;
      }

      button {
        border: 1px solid gray;
        border-radius: 50%;
        width: 40px;
        height: 40px;
        margin: 10px;
      }
    }
  }
}

//// Buttons
.customBtn {
  border-radius: 5px !important;
  border: none !important;
  // box-shadow: 0 0 2px 1px #00000061 !important;
}

.customBtn:hover {
  box-shadow: 0 0 2px 1px #00000061 !important;
}

.BtnContainer {
  text-align: center;
}

.btn-blue {
  background-color: var(--primary-color) !important;
}

.btn-pink {
  background-color: var(--secondary-color) !important;
}

.gwPink {
  color: var(--secondary-color);
}

//// Custom scrollbar
* {
  scrollbar-width: 1px;
  scrollbar-color: black transparent;
}

*::-webkit-scrollbar {
  width: 4px;
  height: 4px;
}

*::-webkit-scrollbar-track {
  background: transparent;
}

*::-webkit-scrollbar-thumb {
  background-color: black;
  border-radius: 20px;
  border: 1px solid transparent;
}

.embed-container {
  position: relative;
  // padding-bottom: 56.25%;
  // height: 5671px;
  overflow: hidden;
  max-width: 100%;

  @include screen-max(820px) {
    height: 9825px !important;
  }
}

.embed-container iframe,
.embed-container object,
.embed-container embed {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.embed-container iframe html body {
  overflow: hidden !important;
}

@media (max-width: 575.98px) {
  .embed-container {
    height: 5550px;
  }

  iframe html .device-mobile-optimized {
    overflow-y: hidden !important;
  }

  .MuiBox-root {
    padding-left: 0px !important;
    padding-right: 0px !important;
  }

  #simple-tab-0 {
    width: min-content;
  }

  #simple-tab-1 {
    width: min-content;
  }
}

//// Success icon on modal
.orderAlert {
  height: 100vh;
  display: flex;
  flex-wrap: nowrap;
  align-content: center;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  position: absolute;
  z-index: 100;
  min-width: 80vw;
}

.success-box {
  color: var(--text-color);
  padding: 25px;
  text-align: center;
  // margin-top: 50%;

  @include screen-max(1200px) {
    // margin-top: 25%;
  }

  .success-checkmark {
    width: 80px;
    height: 115px;
    margin: 0 auto;

    .check-icon {
      width: 80px;
      height: 80px;
      position: relative;
      border-radius: 50%;
      box-sizing: content-box;
      border: 4px solid #4caf50;

      &::before {
        top: 3px;
        left: -2px;
        width: 30px;
        transform-origin: 100% 50%;
        border-radius: 100px 0 0 100px;
      }

      &::after {
        top: 0;
        left: 30px;
        width: 60px;
        transform-origin: 0 50%;
        border-radius: 0 100px 100px 0;
        animation: rotate-circle 4.25s ease-in;
      }

      &::before,
      &::after {
        content: "";
        height: 100px;
        position: absolute;
        // background: #ffffff;
        transform: rotate(-45deg);
      }

      .icon-line {
        height: 5px;
        background-color: #4caf50;
        display: block;
        border-radius: 2px;
        position: absolute;
        z-index: 10;

        &.line-tip {
          top: 46px;
          left: 14px;
          width: 25px;
          transform: rotate(45deg);
          animation: icon-line-tip 0.75s;
        }

        &.line-long {
          top: 38px;
          right: 8px;
          width: 47px;
          transform: rotate(-45deg);
          animation: icon-line-long 0.75s;
        }
      }

      .icon-circle {
        top: -4px;
        left: -4px;
        z-index: 10;
        width: 80px;
        height: 80px;
        border-radius: 50%;
        position: absolute;
        box-sizing: content-box;
        border: 4px solid rgba(76, 175, 80, 0.5);
      }

      .icon-fix {
        top: 8px;
        width: 5px;
        left: 26px;
        z-index: 1;
        height: 85px;
        position: absolute;
        transform: rotate(-45deg);
        // background-color: #ffffff;
      }
    }
  }

  @keyframes rotate-circle {
    0% {
      transform: rotate(-45deg);
    }

    5% {
      transform: rotate(-45deg);
    }

    12% {
      transform: rotate(-405deg);
    }

    100% {
      transform: rotate(-405deg);
    }
  }

  @keyframes icon-line-tip {
    0% {
      width: 0;
      left: 1px;
      top: 19px;
    }

    54% {
      width: 0;
      left: 1px;
      top: 19px;
    }

    70% {
      width: 50px;
      left: -8px;
      top: 37px;
    }

    84% {
      width: 17px;
      left: 21px;
      top: 48px;
    }

    100% {
      width: 25px;
      left: 14px;
      top: 45px;
    }
  }

  @keyframes icon-line-long {
    0% {
      width: 0;
      right: 46px;
      top: 54px;
    }

    65% {
      width: 0;
      right: 46px;
      top: 54px;
    }

    84% {
      width: 55px;
      right: 0px;
      top: 35px;
    }

    100% {
      width: 47px;
      right: 8px;
      top: 38px;
    }
  }
}

//// ICONS custom color touch

.custom_color-stroke {
  stroke: var(--secondary-color);
}

.custom_color-fill {
  fill: var(--secondary-color);
}

@keyframes cssAnimation {
  0% {
    -webkit-transform: scale(0.2) rotate(180deg) skew(0deg) opacity(0.5);
  }

  25% {
    -webkit-transform: scale(0.4) rotate(110deg) skew(0deg) opacity(0.8);
  }

  50% {
    -webkit-transform: scale(0.6) rotate(90deg) skew(0deg) opacity(1);
  }

  100% {
    -webkit-transform: scale(1) rotate(0deg) skew(0deg) opacity(1);
  }
}

.SpinnerLoaderWrapper {
  // position: absolute;
  margin: auto;
  width: 100vw;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--background-color);
  transform: translate(-160px, 0px);

  @include screen-max(1200px) {
    transform: translate(0px, 0px);
  }
}

.country-selector {
  top: 0;
  position: fixed;
  left: 0px;
  bottom: unset !important;
  right: unset !important;

  @media (max-width: 720px) {
    top: 0;
    position: fixed;
    left: unse !important;
    bottom: unset !important;
    right: 0 !important;
  }
}

.place_marker {
  display: flex;
  flex-wrap: nowrap;
  align-content: center;
  justify-content: center;
  align-items: center;
  width: max-content;
  // text-align: center;
  transform: translate(-10px, -35px);

  svg {
    fill: var(--primary-color);
    width: 30px !important;
    height: 30px !important;
    float: left;

    path {
      fill: var(--primary-color);
    }
  }

  span {
    white-space: nowrap;
    width: max-content;
    float: right;
    background-color: var(--primary-color);
    padding: 5px;
    border-radius: 5px;
    color: white;
    font-weight: 400 !important;
  }
}

.gm-control-active {
  display: none;
}

.gmnoprint {
  display: none;
}

.itemSheetDetail {
  .conditions {
    margin: 15px 0;
    color: var(--secondary-color);
  }
}

.ServiceSheet {
  padding-bottom: 0px !important;

  .back {
    @include screen-max(1200px) {
      position: absolute;
    }

    position: relative;
  }

  .contentService {
    gap: 45px;
  }

  .bbody {
    .bookingForm {
      @include screen-max(1200px) {
        display: none;
      }

      color: black;
      height: max-content;
      position: sticky;
      top: 30px;
      min-height: 125px;
      padding: 15px;
      display: flex;
      flex-direction: column;
      flex-wrap: nowrap;
      align-content: center;
      justify-content: center;
      align-items: center;

      .title {
        margin-bottom: 15px;
        font-size: 20px;
      }

      .price-infos {
        text-align: center;
        margin-bottom: 15px;

        i {
          font-size: 12px;
        }

        .price {
          display: flex;
          flex-direction: row;
          flex-wrap: nowrap;
          align-content: center;
          justify-content: center;
          align-items: center;

          h3 {
            margin: 0px;
          }

          .asterisk {
            color: var(--primary-color);
            font-size: 30px;
            transform: translate(0px, -10px);
          }
        }
      }
    }
  }
}

.orderForm {

  // .form-label{
  //     background-color: gray;
  // }
  .quantity {
    align-content: center;
    align-items: center;
    justify-content: center;
    padding: 0;
    margin: 0px 10px;
    width: 100%;

    .form-group {
      margin: 0px !important;
    }

    .quantitySpinner {
      // width: 130px !important;
      display: flex;

      .input-group {
        align-items: center;
      }

      .form-control {
        height: 40px;
        text-align: center;
      }

      button {
        border: 1px solid gray;
        border-radius: 50%;
        width: 40px;
        height: 40px;
        margin: 10px;
        justify-content: center;
        align-content: center;
      }
    }
  }

  .serviceSheets {
    box-shadow: 0 0 5px -3px black;
    padding: 15px;
    margin: 15px 0px;
    width: 100%;

    .quantity {
      align-content: center;
      align-items: center;
      justify-content: center;
      padding: 0;
      margin: auto;
      width: 100%;
      // outline: 3px solid var(--primary-color);
      transform: translate(0px, -5px);

      @include screen-min(1205px) {
        padding: 0px 150px;
      }

      @include screen-max(1200px) {
        padding: 0px 50px;
      }

      .form-group {
        margin: 0px !important;
      }

      .quantitySpinner {
        display: flex;

        .input-group {
          align-items: center;
        }

        .form-control {
          height: 40px;
          text-align: center;
        }

        input:read-only {
          border: none;
          background-color: transparent;
          color: var(--text-color);
        }

        button {
          border: 1px solid gray;
          border-radius: 50%;
          width: 40px;
          height: 40px;
          margin: 10px;
          justify-content: center;
          align-content: center;
          color: var(--text-color);
        }

        button:focus {
          background-color: transparent;
        }

        button:disabled {
          background-color: transparent;
        }
      }
    }

    .buttonSheet {
      .col {
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
        align-content: center;
        justify-content: center;
        align-items: center;

        button {
          margin: 0;
          // .customBtn {
          background-color: var(--secondary-color);
          color: white;
          // }
        }
      }
    }
  }

  padding: 0;
  margin: 0;
  width: 100%;

  #exampleCustomCheckbox:checked+.form-label {
    background-color: brown !important;
  }

  .sheet {
    .sheet-title {
      font-size: 18px !important;
      text-align: center;
      margin: 15px 0px;

      @include screen-max(1200px) {
        font-size: 16px !important;
      }
    }

    h5 {
      margin: 0;
    }

    .item-infos {
      .item-title {
        @include screen-min(1205px) {
          font-size: 16px !important;
        }
      }

      i {
        @include screen-min(1205px) {
          font-size: 13px;
        }
      }
    }

    .typeImg {
      transform: translate(0px, -43px);

      @include screen-max(1205px) {
        transform: translate(0px, -40px);
      }
    }

    .price_container {
      display: flex;
      flex-wrap: nowrap;
      align-items: flex-end;
      justify-content: flex-end;
      // transform: translate(0px, -50px);
      min-width: max-content;

      .priceWrapper {
        .price::before {
          content: "+ ";
        }

        .price {
          background-color: white;
          color: black;
          width: clamp(15px, 100%, 45px);
          padding: 5px 5px 5px 15px;
          border-radius: 10px 0 0 0;
          transform: translate(0px, 10px);
          height: max-content;
          display: flex;
          align-items: center;
          flex-direction: row;
          flex-wrap: nowrap;
          align-content: center;
          justify-content: center;

          @include screen-max(720px) {
            transform: translate(0px, 17px);
            padding: 2px 5px 2px 10px;
          }

          h3 {
            font-size: 15px !important;

            @include screen-max(1200px) {
              // transform: translate(0px, 10px);
              padding: 2px 5px 2px 10px;
            }

            h3 {
              font-size: 20px !important;

              @include screen-max(1200px) {
                font-size: 18px !important;
              }
            }
          }

          .asterisk {
            color: var(--primary-color);
            font-size: 30px;
            transform: translate(0px, -10px);
          }
        }
      }
    }

    .price {
      display: flex;
      flex-direction: row;
      flex-wrap: nowrap;
      align-content: center;
      justify-content: center;
      align-items: center;

      h3 {
        font-size: 12px !important;
        margin: 0px;
        font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
      }

      .asterisk {
        color: var(--primary-color);
        font-size: 30px;
        transform: translate(0px, -10px);
      }
    }

    .sections_header-infos {
      margin: 10px 0px;

      h5 {
        font-size: 16px !important;
      }
    }

    input:checked+.typeCheckboxImg {
      outline: 2px solid var(--primary-color);
      border-radius: 5px;
    }
  }

  .form-label {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: space-between;
    // align-items: ;
    align-content: center;

    figure {
      width: 100%;
      height: 100%;
      text-align: center;
      overflow: hidden;
      margin: 0px;

      @include screen-max(1200px) {
        width: 125px;
      }

      img {
        width: 100%;
        height: 100%;
      }
    }
  }

  .typeRadio {
    overflow: hidden;
    flex-direction: column;

    .form-check-input[type="radio"] {
      margin-right: 15px;
    }

    .item-infos {
      display: flex;
    }

    .quantity {
      outline: none;
      transform: translate(0, 0);
    }
  }

  .typeRadio,
  .typeCheckbox+.quantity {
    border: none;
    transform: translate(0, 0);
  }

  .typeCheckbox {
    overflow: hidden;

    .inputCol {
      width: 30px;
    }

    .form-check-input {
      margin-right: 10px;
    }
  }

  .hidden {
    visibility: hidden;
  }

  .typeCheckboxImg {
    box-shadow: 0 0 5px -3px #c0c0c0;
    border: 1px solid #c0c0c0;
    border-radius: 10px;
    min-height: 125px;
    overflow: hidden;

    #price {
      width: clamp(15px, 100%, 75px);

      @media only screen and (-webkit-min-device-pixel-ratio: 1.5),
      only screen and (min-device-pixel-ratio: 1.5) {
        transform: translate(0px, 15px) !important;
      }
    }

    .item-infos {
      padding: 10px;
      display: flex;
      flex-direction: column;
      flex-wrap: nowrap;
      align-content: center;

      .item-title {
        font-size: 16px !important;

        @include screen-max(1200px) {
          font-size: 14px !important;
        }
      }
    }
  }

  .unavailable {
    color: grey;
    opacity: 0.6;
  }

  .typeRadioImg {
    box-shadow: 0 0 5px -3px black;
    min-height: 125px;
    overflow: hidden;

    .item-infos {
      padding: 10px;
      display: flex;
      flex-direction: column;
      flex-wrap: nowrap;
      align-content: center;

      .item-title {
        font-size: 16px !important;

        @include screen-max(1200px) {
          font-size: 14px !important;
        }
      }
    }

    .price_container {
      display: flex;
      flex-wrap: nowrap;
      align-items: center;
      justify-content: flex-end;
      background-color: var(--background-color);
      padding: 10px;
      width: max-content;
      position: absolute;
      transform: translate(4px, 45px);
      border-radius: 15px 0px 0px 0px;
    }

    .item-attr {
      display: flex;
      flex-direction: row;
      flex-wrap: nowrap;
      align-content: center;
      justify-content: flex-end;
      align-items: center;
    }
  }
}

.OrderSummary {
  display: flex;
  width: 100%;
  margin: 0;

  .sheet_total {
    margin-bottom: 45px;
  }

  .price {
    h3 {
      margin: 0;
      font-size: 18px;

      @include screen-max(1200px) {
        font-family: Arial, Helvetica, sans-serif;
        font-size: 12px !important;
      }
    }
  }

  .text-right {
    text-align: right;
  }

  .main {
    .orderDetail_label {
      color: var(--secondary-color);
    }

    .BtnEditSheet {
      background-color: var(--secondary-color);
      color: white;
      border: none;
      border-radius: 5px;
      padding: 5px 10px;
    }

    padding: 0px;

    .table {
      color: var(--text-color);
    }

    .table>tfoot>tr>th {
      border-bottom-width: 0px;
    }

    .table .priceWrapper {
      .price {
        // background-color: white;
        width: clamp(15px, 100%, 45px);
        padding: 5px 5px 5px 15px;
        border-radius: 10px 0 0 0;
        // transform: translate(0px, -4px);
        height: max-content;

        @include screen-max(720px) {
          padding: 2px 5px 2px 10px;
        }

        h3 {
          font-size: 15px !important;

          @include screen-max(1200px) {
            // transform: translate(0px, 10px);
            padding: 2px 5px 2px 10px;
          }

          h3 {
            font-size: 15px !important;

            @include screen-max(1200px) {
              font-size: 18px !important;
            }
          }

          div {
            display: flex;
            align-items: center;
          }
        }
      }
    }

    .row {
      display: flex;
      flex-direction: row;
      flex-wrap: nowrap;
      align-content: center;
      justify-content: center;
      align-items: center;
      margin: 0;
      padding: 20px 0px;

      @include screen-max(1200px) {
        margin: 10px 0px;
        padding: 0px 15px;
      }
    }
  }
}

input:checked {
  background-color: var(--primary-color) !important;
}

.Price_align-top {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-content: flex-start;
  justify-content: center;
  align-items: flex-start;

  sup {
    font-size: 0.65em;
    top: 0px !important;
    line-height: unset;
  }
}

//YOUTUBE IFRAME

.youtube_iframe {
  width: 100%;
}

//ALENTOUR WIDGET

.price-badge {
  background-color: var(--primary-color) !important;
}

app-experience-preview {
  width: 100% !important;
}

.experiences {
  width: 100% !important;
  display: block !important;
  max-width: 100% !important;
}

app-suggestion-selector {
  max-width: 100% !important;
}

.experience-preview-wrapper .content-wrapper {
  padding: 13px 15px !important;
}

//checkin

.checkin {
  background-color: white;
  width: 100vw;
  height: auto;
  top: 0 !important;
}

.checkin-header {
  border-radius: 10px;
  border: 0px;
}

.checkin-header-img {
  // object-fit: cover !important;
  // object-position: center center !important;
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.confirm-modal {
  height: 100vh;
  background-color: white;
}

.checkin-label {
  font-size: 1.5rem !important;
  font-weight: 600 !important;
  color: var(--primary-color);
}

.checkin-label-margin {
  margin-top: 5vh;
}

.checkin-input {
  display: flex;
  justify-content: center;
  align-items: center;

  input {
    width: 100%;
    height: 100%;
    margin: 0;
    padding: 0;
    border: 0;
  }

  .react-datepicker__close-icon::after {
    background-color: var(--primary-color);
    color: white;
  }

  input:focus {
    outline: none;
  }
}

.checkin-button--color {
  background-color: var(--primary-color);
  color: white;
  border: none;
}

.checkin-button--color:active,
.checkin-button--color:focus {
  background-color: var(--primary-color);
  color: white;
}

.CustomTimePicker {
  .flex {
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;

    .time-periode {
      width: 30%;
    }

    .text {
      text-align: center;

    }
  }
}

.button-signature {
  width: 55px;
  height: 20px;
  font-size: 9px;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
}

.signature-flex {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.signature-height {
  height: 30vh;
  padding: 0px !important;
  margin: 0px !important;
}

.check-box-flex {
  display: flex;
  align-items: flex-start;
}

.check-box-text {
  color: red;
}

.country {
  width: 100%;
  padding: 0px !important;
}

.country-flag {
  font-size: 1.5rem !important;
}

.flex-date-range {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.subpic {
  position: relative;
  width: 100%;
  height: 100%;
}

.flex-toggle-selector {
  display: flex;
  justify-content: space-around;
  align-items: center;
  padding: 0px !important;
  // overflow: hidden;
}

.toggle-box {
  width: 50%;
  height: 100%;
  text-align: center;
}

.toggle-box-background {
  background-color: var(--primary-color);
  transition: background-color 0.2s ease-in-out;
  font-weight: 700;
  color: white;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.5);
  transform: scaleX(1.1);
  transform: scaleY(1.25);
}

.toggle-box-left {
  border-radius: 8px 0 0 8px;
}

.toggle-box-right {
  border-radius: 0 8px 8px 0;
}

.imtext {
  position: absolute;
  z-index: 1;
  color: rgb(65, 64, 64);
  font-weight: bold;
  font-size: 5vw !important;
  width: 100%;
  text-align: center;
  top: 40%;
  transform: translateY(-50%, -10%);
  left: 0;
  right: 0;
}

.imtext-hotel {
  position: absolute;
  z-index: 1;
  color: rgb(65, 64, 64);
  font-weight: bold;
  font-size: 5vw !important;
  width: 100%;
  text-align: center;
  top: 55%;
  transform: translateY(-50%, -10%);
  left: 0;
  right: 0;
}

.imtext-background {
  position: absolute;
  z-index: 1;
  background-color: rgba(255, 255, 255, 0.5);
  width: 100%;
  height: 100%;
  top: 50%;
  transform: translateY(-50%);
  left: 0;
  right: 0;
}

.feedback-flex {
  display: flex;
  align-items: center;
  justify-content: space-around;
}

.icon-selected {
  transform: scale(1.3);
}

.feedback-label-margin {
  margin-top: 3vh;
  margin-bottom: 3vh;
}

.feedback-label {
  font-size: 1.2rem !important;
  font-weight: 600 !important;
  color: var(--primary-color);
}

.feedback-button-text {
  font-weight: 500 !important;
  border: 0;
}

.feedback-back-button {
  background-color: white;
  color: var(--primary-color);
}

.feedback-back-button:hover {
  background-color: white;
  color: rgb(65, 64, 64);
}

.feedback-back-button:active,
.feedback-back-button:focus {
  background-color: var(--primary-color);
  color: white;
}

.feedback-form {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.feedback-valide-button {
  margin-bottom: 2vh !important;
}

// ------------------------------------------------