.page-list > div:not(:last-child) {
  margin-bottom: 15px;
}

.page-list {
  text-align: center; 
}
.page-list .react-pdf__Page {
  display: inline-block;
  margin: auto; 
  
}

@media only screen and (-webkit-min-device-pixel-ratio: 1.5), only screen and (min-device-pixel-ratio: 1.5) {
  .react-pdf__Page canvas {
    width: 214px; 
  }
}

 @media (max-width: 720px) {
  .page-list .react-pdf__Page canvas {
    /* width: 100% !important; */
  }
  
    }
.page-list .react-pdf__Page canvas {
  width: 50%;
  height: auto !important;
  box-shadow: 0 0 1em -0.7em black;
}
