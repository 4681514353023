// @mixin breakpoint($size) {
//     @if $size == mobile {
//       @media (max-width: 599px) { @content; }
//     } @else if $size == tablet-portrait-up {
//       @media (min-width: 600px) { @content; }
//     } @else if $size == tablet-landscape-up {
//       @media (min-width: 900px) { @content; }
//     } @else if $size == desktop-up {
//       @media (min-width: 1200px) { @content; }
//     } @else if $size == big-desktop-up {
//       @media (min-width: 1800px) { @content; }
//     }
// }
// /* used in this .scss file*/
// .logo{
//     .link{
//        img{
//         @include breakpoint(mobile) {
//             width:30%;
//           }
//           width:20%;
//           height:auto;
//        }
//     }
// }

@media only screen and (min-width: 1200px) and (-webkit-min-device-pixel-ratio: 1.5),
    only screen and (min-device-pixel-ratio: 1.5) {
    body {
        font-size: 10.5px !important;
        h4 {
            font-size: 15px !important;
        }
        h5 {
            font-size: 14px !important;
        }
        p {
            font-size: 12px !important;
        }
        button {
            font-size: 12px !important;
        }
        .container {
            max-width: 960px;
        }
    }

    ////pages/home/index.jsx

    .MenuLeft {
        .nav {
            .nav-item {
                margin: 5px 10px;
            }
            a {
                font-size: 11px !important;

                svg {
                    width: 15px;
                    height: 15px;
                }
            }
        }
    }

    ////items/index.jsx

    .mainItems {
        padding: 90px 30px !important;

        .itemsGroup {
            .item {
                height: 344px !important;
                width: 175px !important;
                margin: 10px;

                a {
                    .cardImg-top {
                        height: 185px !important;
                    }

                    .detailPrice {
                    }

                    .card-title {
                        width: 163px;
                        font-size: 13px !important;
                    }

                    .asterisk-details p {
                        width: 163px !important;
                    }

                    .itemBody {
                        .description {
                            font-size: 0.575rem !important;
                        }
                    }
                }
            }
        }
    }

    ////pages/aroundHoster/OfferDetails.jsx

    .OfferSection {
        .bbody {
            .sectionOffer {
                transform: translate(-90px, 0px);
                .infoOffer {
                    .title {
                        font-size: 1rem;
                    }
                }

                .itemDetail {
                    .Offer {
                        span {
                            font-size: 15px !important;
                        }
                    }
                }
            }

            .Infos {
                .description {
                    svg {
                        width: 12px;
                        height: 12px;
                    }

                    .open {
                        svg {
                            width: 18px;
                            height: 18px;
                        }
                    }
                }
            }

            .BtnContainer {
                .customBtn {
                    font-size: 12px !important;
                }
            }
        }
    }

    //// pages/aroundHoster/ActivityDetails.jsx
    .ActivitySection {
        .bbody {
            .ActivityBody {
                display: flex;
                flex-wrap: nowrap !important;
                flex-direction: row;
                padding: 0px;
                margin: 0px !important;

                .sectionActivity {
                    transform: translate(0px, 0px);
                    .infoActivity {
                        .title {
                            font-size: 18px !important;
                        }
                    }
                    .itemDetail {
                        h4 {
                            font-size: 13px !important;
                        }
                    }
                }
                .Infos {
                    .price {
                        span {
                            font-size: 10px !important;
                        }
                    }
                    .btn-book {
                        font-size: 10px !important;
                    }
                }
            }
        }
    }

    //// components/activities/activities.jsx
    .mainActivities {
        .activitiesGroup {
            .activity {
                margin-bottom: 10px;
                height: 165px !important;

                .img {
                    .pills {
                        transform: translate(-10px, 18px);

                        svg {
                            transform: translate(0px, -11px);
                        }
                    }
                }

                .Activity {
                    font-weight: 700;
                    color: var(--secondary-color);
                    transform: translate(292px, -73px);
                    margin-left: 0px;
                    width: 388px;
                    text-align: center;
                }

                .cardImg-top {
                    height: 165px !important;
                    transform: translate(0px, -18px);
                }
                .card-title {
                    width: 540px;
                }
                .description {
                    .distance {
                        font-size: 10px;
                    }
                }
            }
        }
    }

    ////components/offers/offers.jsx
    .mainOffers {
        .offersGroup {
            .offer {

                .Offer {
                    transform: translate(300px, -60px);
                }
                .cardImg-top {
                    transform: translate(0px, -15.2px);
                }
                .img {
                    .pills {
                        svg {
                            transform: translate(0, -11px);
                            // transform: translate(0px, -12.5px);
                        }
                    }
                }
                .description {
                    .distance {
                        font-size: 10px !important;
                    }
                }
            }
        }
    }

    ////Service/ServiceDetail.jsx

    .Service {
        .back {
            margin-left: 0;
            transform: translate(-10px, 0px);
        }

        .bbody {
            .m-0 {
                padding: 0 50px;
                --bs-gutter-x: 0;
            }
            .cardImg-top {
                height: 350px;
            }

            .sectionService {
                .infoService {
                    .headerService {
                        .title {
                            font-size: 1rem;
                            width: 40ch;
                        }
                        .priceWrapper {
                            .price {
                                h3 {
                                    font-size: 25px !important;
                                }
                            }
                        }
                    }
                }
            }
            .bookingForm {
                margin-top: 0px;
                .title {
                    font-size: 1rem;
                }
                .infosForm {
                    h5 {
                        font-size: 10px !important;
                    }
                    .form-group input {
                        font-size: 0.7rem;
                    }

                    .form-label {
                        font-size: 10px !important;
                    }

                    .BtnContainer {
                        .customBtn {
                            font-size: 0.8rem;
                        }
                    }

                    .quantitySpinner {
                        .form-control {
                            font-size: 0.7rem;
                        }
                    }
                }
            }
        }
    }

    ////
    .SpinnerLoaderWrapper {
        transform: translate(-120px, 0px);
    }
}
